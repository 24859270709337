// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.3
//   protoc               v5.28.3
// source: dealershipTemplate/v1/enums/DealershipTemplateFieldType.proto

/* eslint-disable */

export const protobufPackage = "dealershipTemplate.v1.enums.dealershipTemplateFieldType";

export enum EDealershipTemplateFieldType {
  Unspecified = 0,
  DealershipSignature = 1,
  DealershipName = 2,
  DealershipPhone = 3,
  DealershipEmail = 4,
  DealershipStreetAndNumber = 5,
  DealershipCity = 6,
  DealershipState = 7,
  DealershipZip = 8,
  VehicleStockNumber = 9,
  VehicleYear = 10,
  VehicleMake = 11,
  VehicleModel = 12,
  VehicleTrim = 13,
  VehicleVin = 14,
  VehicleColor = 15,
  VehicleMileage = 16,
  VehicleCashPrice = 17,
  CashDown = 18,
  InsuranceCompanyName = 19,
  InsuranceEffectiveDateFrom = 20,
  InsuranceEffectiveDateTo = 21,
  InsurancePolicyNumber = 22,
  InsuranceAgentName = 23,
  InsuranceCompanyState = 24,
  InsuranceCompanyZip = 25,
  InsuranceCompanyCity = 26,
  InsuranceStreetAndNumber = 27,
  SalesPersonName = 28,
  TradeVehicleTrim = 29,
  TradeVehicleMake = 30,
  TradeVehicleModel = 31,
  TradeVehicleYear = 32,
  TradeVehicleVin = 33,
  TradeVehicleLicensePlate = 34,
  TradeVehicleMileage = 35,
  LenderName = 36,
  ServiceContract = 37,
  VehicleBody = 38,
  VehicleTransmission = 39,
  DeliverDate = 40,
  TotalPrice = 41,
  PlateFee = 42,
  DocumentFee = 43,
  VehicleGaragedAddress = 44,
  GapContractDescription = 45,
  GapContractPrice = 46,
  ProtectionProductsPrice = 47,
  DueAtSigning = 48,
  TradePayOff = 49,
  DmsDealNumber = 50,
  LienHolderName = 51,
  LienHolderAddress = 52,
  TaxName1 = 53,
  TaxValue1 = 54,
  TaxName2 = 55,
  TaxValue2 = 56,
  TaxName3 = 57,
  TaxValue3 = 58,
  TaxName4 = 59,
  TaxValue4 = 60,
  TaxName5 = 61,
  TaxValue5 = 62,
  TaxName6 = 63,
  TaxValue6 = 64,
  TaxName7 = 65,
  TaxValue7 = 66,
  TaxName8 = 67,
  TaxValue8 = 68,
  TaxName9 = 69,
  TaxValue9 = 70,
  TaxName10 = 71,
  TaxValue10 = 72,
  TaxName11 = 73,
  TaxValue11 = 74,
  TaxName12 = 75,
  TaxValue12 = 76,
  TaxName13 = 77,
  TaxValue13 = 78,
  TaxName14 = 79,
  TaxValue14 = 80,
  TaxName15 = 81,
  TaxValue15 = 82,
  AccessoryName1 = 83,
  AccessoryVendor1 = 84,
  AccessoryPrice1 = 85,
  AccessoryName2 = 86,
  AccessoryVendor2 = 87,
  AccessoryPrice2 = 88,
  AccessoryName3 = 89,
  AccessoryVendor3 = 90,
  AccessoryPrice3 = 91,
  AccessoryName4 = 92,
  AccessoryVendor4 = 93,
  AccessoryPrice4 = 94,
  AccessoryName5 = 95,
  AccessoryVendor5 = 96,
  AccessoryPrice5 = 97,
  AccessoryName6 = 98,
  AccessoryVendor6 = 99,
  AccessoryPrice6 = 100,
  AccessoryName7 = 101,
  AccessoryVendor7 = 102,
  AccessoryPrice7 = 103,
  AccessoryName8 = 104,
  AccessoryVendor8 = 105,
  AccessoryPrice8 = 106,
  AccessoryName9 = 107,
  AccessoryVendor9 = 108,
  AccessoryPrice9 = 109,
  AccessoryName10 = 110,
  AccessoryVendor10 = 111,
  AccessoryPrice10 = 112,
  AccessoryName11 = 113,
  AccessoryVendor11 = 114,
  AccessoryPrice11 = 115,
  AccessoryName12 = 116,
  AccessoryVendor12 = 117,
  AccessoryPrice12 = 118,
  AccessoryName13 = 119,
  AccessoryVendor13 = 120,
  AccessoryPrice13 = 121,
  AccessoryName14 = 122,
  AccessoryVendor14 = 123,
  AccessoryPrice14 = 124,
  AccessoryName15 = 125,
  AccessoryVendor15 = 126,
  AccessoryPrice15 = 127,
  ServiceContractDescription = 128,
  ServiceContractPrice = 129,
  BasePayment = 130,
  AccessoriesTotalPrice = 131,
  InsuranceCompanyPhoneNumber = 132,
  ProtectionProductName1 = 133,
  ProtectionProductPrice1 = 134,
  ProtectionProductDescription1 = 135,
  ProtectionProductName2 = 136,
  ProtectionProductPrice2 = 137,
  ProtectionProductDescription2 = 138,
  ProtectionProductName3 = 139,
  ProtectionProductPrice3 = 140,
  ProtectionProductDescription3 = 141,
  ProtectionProductName4 = 142,
  ProtectionProductPrice4 = 143,
  ProtectionProductDescription4 = 144,
  ProtectionProductName5 = 145,
  ProtectionProductPrice5 = 146,
  ProtectionProductDescription5 = 147,
  ProtectionProductName6 = 148,
  ProtectionProductPrice6 = 149,
  ProtectionProductDescription6 = 150,
  ProtectionProductName7 = 151,
  ProtectionProductPrice7 = 152,
  ProtectionProductDescription7 = 153,
  ProtectionProductName8 = 154,
  ProtectionProductPrice8 = 155,
  ProtectionProductDescription8 = 156,
  ProtectionProductName9 = 157,
  ProtectionProductPrice9 = 158,
  ProtectionProductDescription9 = 159,
  ProtectionProductName10 = 160,
  ProtectionProductPrice10 = 161,
  ProtectionProductDescription10 = 162,
  Common_Shared_FreeText = 163,
  Common_Shared_CheckBox = 164,
  AllProtectionProductsPrice = 165,
  TotalFees = 166,
  TotalRebates = 167,
  SecondTradeVehicleTrim = 168,
  SecondTradeVehicleMake = 169,
  SecondTradeVehicleModel = 170,
  SecondTradeVehicleYear = 171,
  SecondTradeVehicleVin = 172,
  SecondTradeVehicleMileage = 173,
  LienHolderCity = 174,
  LienHolderState = 175,
  LienHolderZip = 176,
  LienHolderStreet = 177,
  TradeLienHolderName = 178,
  TradeLienHolderCity = 179,
  TradeLienHolderState = 180,
  TradeLienHolderZip = 181,
  TradeLienHolderStreet = 182,
  PolicyHolderName = 183,
  PolicyHolderCity = 184,
  PolicyHolderState = 185,
  PolicyHolderZip = 186,
  PolicyHolderStreet = 187,
  Payment = 188,
  TaxRate = 189,
  TotalFinanceAmount = 190,
  DealType = 191,
  TradeValue = 192,
  SecondTradeValue = 193,
  TradeNetValue = 194,
  SecondTradeNetValue = 195,
  TotalTaxes = 196,
  TaxableFieldName1 = 197,
  TaxableFieldValue1 = 198,
  TaxableFieldName2 = 199,
  TaxableFieldValue2 = 200,
  TaxableFieldName3 = 201,
  TaxableFieldValue3 = 202,
  TaxableFieldName4 = 203,
  TaxableFieldValue4 = 204,
  TaxableFieldName5 = 205,
  TaxableFieldValue5 = 206,
  TaxableFieldName6 = 207,
  TaxableFieldValue6 = 208,
  TaxableFieldName7 = 209,
  TaxableFieldValue7 = 210,
  TaxableFieldName8 = 211,
  TaxableFieldValue8 = 212,
  TaxableFieldName9 = 213,
  TaxableFieldValue9 = 214,
  TaxableFieldName10 = 215,
  TaxableFieldValue10 = 216,
  TaxableFieldName11 = 217,
  TaxableFieldValue11 = 218,
  TaxableFieldName12 = 219,
  TaxableFieldValue12 = 220,
  TaxableFieldName13 = 221,
  TaxableFieldValue13 = 222,
  TaxableFieldName14 = 223,
  TaxableFieldValue14 = 224,
  TaxableFieldName15 = 225,
  TaxableFieldValue15 = 226,
  NonTaxableFeeName1 = 227,
  NonTaxableFeeValue1 = 228,
  NonTaxableFeeName2 = 229,
  NonTaxableFeeValue2 = 230,
  NonTaxableFeeName3 = 231,
  NonTaxableFeeValue3 = 232,
  NonTaxableFeeName4 = 233,
  NonTaxableFeeValue4 = 234,
  NonTaxableFeeName5 = 235,
  NonTaxableFeeValue5 = 236,
  NonTaxableFeeName6 = 237,
  NonTaxableFeeValue6 = 238,
  NonTaxableFeeName7 = 239,
  NonTaxableFeeValue7 = 240,
  NonTaxableFeeName8 = 241,
  NonTaxableFeeValue8 = 242,
  NonTaxableFeeName9 = 243,
  NonTaxableFeeValue9 = 244,
  NonTaxableFeeName10 = 245,
  NonTaxableFeeValue10 = 246,
  NonTaxableFeeName11 = 247,
  NonTaxableFeeValue11 = 248,
  NonTaxableFeeName12 = 249,
  NonTaxableFeeValue12 = 250,
  NonTaxableFeeName13 = 251,
  NonTaxableFeeValue13 = 252,
  NonTaxableFeeName14 = 253,
  NonTaxableFeeValue14 = 254,
  NonTaxableFeeName15 = 255,
  NonTaxableFeeValue15 = 256,
  CancelableProductName1 = 257,
  CancelableProductPrice1 = 258,
  CancelableProductName2 = 259,
  CancelableProductPrice2 = 260,
  CancelableProductName3 = 261,
  CancelableProductPrice3 = 262,
  CancelableProductName4 = 263,
  CancelableProductPrice4 = 264,
  CancelableProductName5 = 265,
  CancelableProductPrice5 = 266,
  CancelableProductName6 = 267,
  CancelableProductPrice6 = 268,
  CancelableProductName7 = 269,
  CancelableProductPrice7 = 270,
  CancelableProductName8 = 271,
  CancelableProductPrice8 = 272,
  CancelableProductName9 = 273,
  CancelableProductPrice9 = 274,
  CancelableProductName10 = 275,
  CancelableProductPrice10 = 276,
  CancelableProductName11 = 277,
  CancelableProductPrice11 = 278,
  CancelableProductName12 = 279,
  CancelableProductPrice12 = 280,
  CancelableProductName13 = 281,
  CancelableProductPrice13 = 282,
  CancelableProductName14 = 283,
  CancelableProductPrice14 = 284,
  CancelableProductName15 = 285,
  CancelableProductPrice15 = 286,
  TaxableSubTotal = 287,
  Subtotal = 288,
  TotalFeesWithoutDoc = 289,
  TotalTradesValue = 290,
  DoingBusinessAs = 291,
  EmployerIdentificationNumber = 292,
  TradeDifference = 293,
  ProductName = 294,
  ProductPrice = 295,
  ProductMileage = 296,
  ProductTerm = 297,
  Customer_Shared_CreditScore = 298,
  Customer_Shared_FirstName = 299,
  Customer_Shared_LastName = 300,
  Customer_Shared_MiddleName = 301,
  Customer_Shared_DateOfBirth = 302,
  Customer_Shared_Initials = 303,
  Customer_Shared_PhoneNumber = 304,
  Customer_Shared_SecondaryPhone = 305,
  Customer_Shared_Email = 306,
  Customer_Shared_Ssn = 307,
  Customer_Shared_StreetAndNumber = 308,
  Customer_Shared_City = 309,
  Customer_Shared_State = 310,
  Customer_Shared_Zip = 311,
  Customer_Shared_County = 312,
  Customer_Shared_DriverLicenseNumber = 313,
  Customer_Shared_PreviousStreetAndNumber = 314,
  Customer_Shared_PreviousCity = 315,
  Customer_Shared_PreviousState = 316,
  Customer_Shared_PreviousZip = 317,
  Customer_Shared_PreviousCounty = 318,
  Customer_Shared_TimeAtPreviousResidence = 319,
  Customer_Shared_ResidenceType = 320,
  Customer_Shared_AddressLine2 = 321,
  Customer_Shared_RentMortgagePayment = 322,
  Customer_Shared_TimeAtResidence = 323,
  Customer_Shared_JobTitle = 324,
  Customer_Shared_Employer = 325,
  Customer_Shared_Income = 326,
  Customer_Shared_OtherIncome = 327,
  Customer_Shared_PreviousEmployee = 328,
  Customer_Shared_EmployerPhone = 329,
  Customer_Shared_EmploymentStatus = 330,
  Customer_Shared_TimeAtCurrentJob = 331,
  Customer_Shared_YearsOfEmployment = 332,
  Customer_Shared_MonthsOfEmployment = 333,
  Customer_Shared_Date = 334,
  Customer_Shared_Signature = 335,
  Date = 336,
  TradeAccountNumber = 337,
  TradePayoffGoodUntilDate = 339,
  TradePerDiem = 340,
  Customer_Shared_FullName = 341,
  Customer_Shared_FullAddress = 342,
  NonCancelableProductName1 = 343,
  NonCancelableProductPrice1 = 344,
  NonCancelableProductName2 = 345,
  NonCancelableProductPrice2 = 346,
  NonCancelableProductName3 = 347,
  NonCancelableProductPrice3 = 348,
  NonCancelableProductName4 = 349,
  NonCancelableProductPrice4 = 350,
  NonCancelableProductName5 = 351,
  NonCancelableProductPrice5 = 352,
  NonCancelableProductName6 = 353,
  NonCancelableProductPrice6 = 354,
  NonCancelableProductName7 = 355,
  NonCancelableProductPrice7 = 356,
  NonCancelableProductName8 = 357,
  NonCancelableProductPrice8 = 358,
  NonCancelableProductName9 = 359,
  NonCancelableProductPrice9 = 360,
  NonCancelableProductName10 = 361,
  NonCancelableProductPrice10 = 362,
  NonCancelableProductName11 = 363,
  NonCancelableProductPrice11 = 364,
  NonCancelableProductName12 = 365,
  NonCancelableProductPrice12 = 366,
  NonCancelableProductName13 = 367,
  NonCancelableProductPrice13 = 368,
  NonCancelableProductName14 = 369,
  NonCancelableProductPrice14 = 370,
  NonCancelableProductName15 = 371,
  NonCancelableProductPrice15 = 372,
  TotalFrontEndPrice = 373,
  VehicleTaxableAmount = 374,
  VehicleTotalAmount = 375,
  VehicleTotalAmountWithoutPayoff = 376,
  SellingPrice = 377,
  SalesPersonEmail = 378,
  APR = 379,
  DeclinedProductName1 = 380,
  DeclinedProductMonthlyPrice1 = 381,
  DeclinedProductPrice1 = 382,
  DeclinedProductTerm1 = 383,
  DeclinedProductMileage1 = 384,
  DeclinedProductName2 = 385,
  DeclinedProductMonthlyPrice2 = 386,
  DeclinedProductPrice2 = 387,
  DeclinedProductTerm2 = 388,
  DeclinedProductMileage2 = 389,
  DeclinedProductName3 = 390,
  DeclinedProductMonthlyPrice3 = 391,
  DeclinedProductPrice3 = 392,
  DeclinedProductTerm3 = 393,
  DeclinedProductMileage3 = 394,
  DeclinedProductName4 = 395,
  DeclinedProductMonthlyPrice4 = 396,
  DeclinedProductPrice4 = 397,
  DeclinedProductTerm4 = 398,
  DeclinedProductMileage4 = 399,
  DeclinedProductName5 = 400,
  DeclinedProductMonthlyPrice5 = 401,
  DeclinedProductPrice5 = 402,
  DeclinedProductTerm5 = 403,
  DeclinedProductMileage5 = 404,
  DeclinedProductName6 = 405,
  DeclinedProductMonthlyPrice6 = 406,
  DeclinedProductPrice6 = 407,
  DeclinedProductTerm6 = 408,
  DeclinedProductMileage6 = 409,
  DeclinedProductName7 = 410,
  DeclinedProductMonthlyPrice7 = 411,
  DeclinedProductPrice7 = 412,
  DeclinedProductTerm7 = 413,
  DeclinedProductMileage7 = 414,
  DeclinedProductName8 = 415,
  DeclinedProductMonthlyPrice8 = 416,
  DeclinedProductPrice8 = 417,
  DeclinedProductTerm8 = 418,
  DeclinedProductMileage8 = 419,
  DeclinedProductName9 = 420,
  DeclinedProductMonthlyPrice9 = 421,
  DeclinedProductPrice9 = 422,
  DeclinedProductTerm9 = 423,
  DeclinedProductMileage9 = 424,
  DeclinedProductName10 = 425,
  DeclinedProductMonthlyPrice10 = 426,
  DeclinedProductPrice10 = 427,
  DeclinedProductTerm10 = 428,
  DeclinedProductMileage10 = 429,
  DeclinedProductName11 = 430,
  DeclinedProductMonthlyPrice11 = 431,
  DeclinedProductPrice11 = 432,
  DeclinedProductTerm11 = 433,
  DeclinedProductMileage11 = 434,
  DeclinedProductName12 = 435,
  DeclinedProductMonthlyPrice12 = 436,
  DeclinedProductPrice12 = 437,
  DeclinedProductTerm12 = 438,
  DeclinedProductMileage12 = 439,
  DeclinedProductName13 = 440,
  DeclinedProductMonthlyPrice13 = 441,
  DeclinedProductPrice13 = 442,
  DeclinedProductTerm13 = 443,
  DeclinedProductMileage13 = 444,
  DeclinedProductName14 = 445,
  DeclinedProductMonthlyPrice14 = 446,
  DeclinedProductPrice14 = 447,
  DeclinedProductTerm14 = 448,
  DeclinedProductMileage14 = 449,
  DeclinedProductName15 = 450,
  DeclinedProductMonthlyPrice15 = 451,
  DeclinedProductPrice15 = 452,
  DeclinedProductTerm15 = 453,
  DeclinedProductMileage15 = 454,
  SelectedTerm = 455,
  Customer_Shared_PreviousFullAddress = 456,
  Customer_Shared_IncomeInterval = 457,
  VehicleFuelType = 458,
  TotalMileageAtLeaseEnd = 459,
  PaymentScheduleType = 460,
  GstTax = 461,
  PstTax = 462,
  LuxuryTax = 463,
  NumberOfPayments = 464,
  VehicleInteriorColor = 465,
  AcquisitionFee = 466,
  TradeColor = 467,
  SecondTradeColor = 468,
  SecondTradePayOff = 469,
  LeaseMileage = 470,
  Customer_Shared_DriverLicenseState = 471,
  ServiceContractTermInYears = 472,
  ServiceContractMileage = 473,
  CapitalizedFeeName1 = 474,
  CapitalizedFeeValue1 = 475,
  CapitalizedFeeName2 = 476,
  CapitalizedFeeValue2 = 477,
  CapitalizedFeeName3 = 478,
  CapitalizedFeeValue3 = 479,
  CapitalizedFeeName4 = 480,
  CapitalizedFeeValue4 = 481,
  CapitalizedFeeName5 = 482,
  CapitalizedFeeValue5 = 483,
  CapitalizedFeeName6 = 484,
  CapitalizedFeeValue6 = 485,
  CapitalizedFeeName7 = 486,
  CapitalizedFeeValue7 = 487,
  CapitalizedFeeName8 = 488,
  CapitalizedFeeValue8 = 489,
  CapitalizedFeeName9 = 490,
  CapitalizedFeeValue9 = 491,
  CapitalizedFeeName10 = 492,
  CapitalizedFeeValue10 = 493,
  CapitalizedFeeName11 = 494,
  CapitalizedFeeValue11 = 495,
  CapitalizedFeeName12 = 496,
  CapitalizedFeeValue12 = 497,
  CapitalizedFeeName13 = 498,
  CapitalizedFeeValue13 = 499,
  CapitalizedFeeName14 = 500,
  CapitalizedFeeValue14 = 501,
  CapitalizedFeeName15 = 502,
  CapitalizedFeeValue15 = 503,
  UpfrontFeeName1 = 504,
  UpfrontFeeValue1 = 505,
  UpfrontFeeName2 = 506,
  UpfrontFeeValue2 = 507,
  UpfrontFeeName3 = 508,
  UpfrontFeeValue3 = 509,
  UpfrontFeeName4 = 510,
  UpfrontFeeValue4 = 511,
  UpfrontFeeName5 = 512,
  UpfrontFeeValue5 = 513,
  UpfrontFeeName6 = 514,
  UpfrontFeeValue6 = 515,
  UpfrontFeeName7 = 516,
  UpfrontFeeValue7 = 517,
  UpfrontFeeName8 = 518,
  UpfrontFeeValue8 = 519,
  UpfrontFeeName9 = 520,
  UpfrontFeeValue9 = 521,
  UpfrontFeeName10 = 522,
  UpfrontFeeValue10 = 523,
  UpfrontFeeName11 = 524,
  UpfrontFeeValue11 = 525,
  UpfrontFeeName12 = 526,
  UpfrontFeeValue12 = 527,
  UpfrontFeeName13 = 528,
  UpfrontFeeValue13 = 529,
  UpfrontFeeName14 = 530,
  UpfrontFeeValue14 = 531,
  UpfrontFeeName15 = 532,
  UpfrontFeeValue15 = 533,
  CapitalizedFees = 534,
  StateTax = 535,
  CountyTax = 536,
  CityTax = 537,
  UpfrontFees = 538,
  MonthlyTaxPayment = 539,
  TitleRegistrationFee = 540,
  TotalPriceWithOptions = 541,
  TotalSellingPrice = 542,
  CustomField = 543,
  UNRECOGNIZED = -1,
}

export function eDealershipTemplateFieldTypeFromJSON(object: any): EDealershipTemplateFieldType {
  switch (object) {
    case 0:
    case "Unspecified":
      return EDealershipTemplateFieldType.Unspecified;
    case 1:
    case "DealershipSignature":
      return EDealershipTemplateFieldType.DealershipSignature;
    case 2:
    case "DealershipName":
      return EDealershipTemplateFieldType.DealershipName;
    case 3:
    case "DealershipPhone":
      return EDealershipTemplateFieldType.DealershipPhone;
    case 4:
    case "DealershipEmail":
      return EDealershipTemplateFieldType.DealershipEmail;
    case 5:
    case "DealershipStreetAndNumber":
      return EDealershipTemplateFieldType.DealershipStreetAndNumber;
    case 6:
    case "DealershipCity":
      return EDealershipTemplateFieldType.DealershipCity;
    case 7:
    case "DealershipState":
      return EDealershipTemplateFieldType.DealershipState;
    case 8:
    case "DealershipZip":
      return EDealershipTemplateFieldType.DealershipZip;
    case 9:
    case "VehicleStockNumber":
      return EDealershipTemplateFieldType.VehicleStockNumber;
    case 10:
    case "VehicleYear":
      return EDealershipTemplateFieldType.VehicleYear;
    case 11:
    case "VehicleMake":
      return EDealershipTemplateFieldType.VehicleMake;
    case 12:
    case "VehicleModel":
      return EDealershipTemplateFieldType.VehicleModel;
    case 13:
    case "VehicleTrim":
      return EDealershipTemplateFieldType.VehicleTrim;
    case 14:
    case "VehicleVin":
      return EDealershipTemplateFieldType.VehicleVin;
    case 15:
    case "VehicleColor":
      return EDealershipTemplateFieldType.VehicleColor;
    case 16:
    case "VehicleMileage":
      return EDealershipTemplateFieldType.VehicleMileage;
    case 17:
    case "VehicleCashPrice":
      return EDealershipTemplateFieldType.VehicleCashPrice;
    case 18:
    case "CashDown":
      return EDealershipTemplateFieldType.CashDown;
    case 19:
    case "InsuranceCompanyName":
      return EDealershipTemplateFieldType.InsuranceCompanyName;
    case 20:
    case "InsuranceEffectiveDateFrom":
      return EDealershipTemplateFieldType.InsuranceEffectiveDateFrom;
    case 21:
    case "InsuranceEffectiveDateTo":
      return EDealershipTemplateFieldType.InsuranceEffectiveDateTo;
    case 22:
    case "InsurancePolicyNumber":
      return EDealershipTemplateFieldType.InsurancePolicyNumber;
    case 23:
    case "InsuranceAgentName":
      return EDealershipTemplateFieldType.InsuranceAgentName;
    case 24:
    case "InsuranceCompanyState":
      return EDealershipTemplateFieldType.InsuranceCompanyState;
    case 25:
    case "InsuranceCompanyZip":
      return EDealershipTemplateFieldType.InsuranceCompanyZip;
    case 26:
    case "InsuranceCompanyCity":
      return EDealershipTemplateFieldType.InsuranceCompanyCity;
    case 27:
    case "InsuranceStreetAndNumber":
      return EDealershipTemplateFieldType.InsuranceStreetAndNumber;
    case 28:
    case "SalesPersonName":
      return EDealershipTemplateFieldType.SalesPersonName;
    case 29:
    case "TradeVehicleTrim":
      return EDealershipTemplateFieldType.TradeVehicleTrim;
    case 30:
    case "TradeVehicleMake":
      return EDealershipTemplateFieldType.TradeVehicleMake;
    case 31:
    case "TradeVehicleModel":
      return EDealershipTemplateFieldType.TradeVehicleModel;
    case 32:
    case "TradeVehicleYear":
      return EDealershipTemplateFieldType.TradeVehicleYear;
    case 33:
    case "TradeVehicleVin":
      return EDealershipTemplateFieldType.TradeVehicleVin;
    case 34:
    case "TradeVehicleLicensePlate":
      return EDealershipTemplateFieldType.TradeVehicleLicensePlate;
    case 35:
    case "TradeVehicleMileage":
      return EDealershipTemplateFieldType.TradeVehicleMileage;
    case 36:
    case "LenderName":
      return EDealershipTemplateFieldType.LenderName;
    case 37:
    case "ServiceContract":
      return EDealershipTemplateFieldType.ServiceContract;
    case 38:
    case "VehicleBody":
      return EDealershipTemplateFieldType.VehicleBody;
    case 39:
    case "VehicleTransmission":
      return EDealershipTemplateFieldType.VehicleTransmission;
    case 40:
    case "DeliverDate":
      return EDealershipTemplateFieldType.DeliverDate;
    case 41:
    case "TotalPrice":
      return EDealershipTemplateFieldType.TotalPrice;
    case 42:
    case "PlateFee":
      return EDealershipTemplateFieldType.PlateFee;
    case 43:
    case "DocumentFee":
      return EDealershipTemplateFieldType.DocumentFee;
    case 44:
    case "VehicleGaragedAddress":
      return EDealershipTemplateFieldType.VehicleGaragedAddress;
    case 45:
    case "GapContractDescription":
      return EDealershipTemplateFieldType.GapContractDescription;
    case 46:
    case "GapContractPrice":
      return EDealershipTemplateFieldType.GapContractPrice;
    case 47:
    case "ProtectionProductsPrice":
      return EDealershipTemplateFieldType.ProtectionProductsPrice;
    case 48:
    case "DueAtSigning":
      return EDealershipTemplateFieldType.DueAtSigning;
    case 49:
    case "TradePayOff":
      return EDealershipTemplateFieldType.TradePayOff;
    case 50:
    case "DmsDealNumber":
      return EDealershipTemplateFieldType.DmsDealNumber;
    case 51:
    case "LienHolderName":
      return EDealershipTemplateFieldType.LienHolderName;
    case 52:
    case "LienHolderAddress":
      return EDealershipTemplateFieldType.LienHolderAddress;
    case 53:
    case "TaxName1":
      return EDealershipTemplateFieldType.TaxName1;
    case 54:
    case "TaxValue1":
      return EDealershipTemplateFieldType.TaxValue1;
    case 55:
    case "TaxName2":
      return EDealershipTemplateFieldType.TaxName2;
    case 56:
    case "TaxValue2":
      return EDealershipTemplateFieldType.TaxValue2;
    case 57:
    case "TaxName3":
      return EDealershipTemplateFieldType.TaxName3;
    case 58:
    case "TaxValue3":
      return EDealershipTemplateFieldType.TaxValue3;
    case 59:
    case "TaxName4":
      return EDealershipTemplateFieldType.TaxName4;
    case 60:
    case "TaxValue4":
      return EDealershipTemplateFieldType.TaxValue4;
    case 61:
    case "TaxName5":
      return EDealershipTemplateFieldType.TaxName5;
    case 62:
    case "TaxValue5":
      return EDealershipTemplateFieldType.TaxValue5;
    case 63:
    case "TaxName6":
      return EDealershipTemplateFieldType.TaxName6;
    case 64:
    case "TaxValue6":
      return EDealershipTemplateFieldType.TaxValue6;
    case 65:
    case "TaxName7":
      return EDealershipTemplateFieldType.TaxName7;
    case 66:
    case "TaxValue7":
      return EDealershipTemplateFieldType.TaxValue7;
    case 67:
    case "TaxName8":
      return EDealershipTemplateFieldType.TaxName8;
    case 68:
    case "TaxValue8":
      return EDealershipTemplateFieldType.TaxValue8;
    case 69:
    case "TaxName9":
      return EDealershipTemplateFieldType.TaxName9;
    case 70:
    case "TaxValue9":
      return EDealershipTemplateFieldType.TaxValue9;
    case 71:
    case "TaxName10":
      return EDealershipTemplateFieldType.TaxName10;
    case 72:
    case "TaxValue10":
      return EDealershipTemplateFieldType.TaxValue10;
    case 73:
    case "TaxName11":
      return EDealershipTemplateFieldType.TaxName11;
    case 74:
    case "TaxValue11":
      return EDealershipTemplateFieldType.TaxValue11;
    case 75:
    case "TaxName12":
      return EDealershipTemplateFieldType.TaxName12;
    case 76:
    case "TaxValue12":
      return EDealershipTemplateFieldType.TaxValue12;
    case 77:
    case "TaxName13":
      return EDealershipTemplateFieldType.TaxName13;
    case 78:
    case "TaxValue13":
      return EDealershipTemplateFieldType.TaxValue13;
    case 79:
    case "TaxName14":
      return EDealershipTemplateFieldType.TaxName14;
    case 80:
    case "TaxValue14":
      return EDealershipTemplateFieldType.TaxValue14;
    case 81:
    case "TaxName15":
      return EDealershipTemplateFieldType.TaxName15;
    case 82:
    case "TaxValue15":
      return EDealershipTemplateFieldType.TaxValue15;
    case 83:
    case "AccessoryName1":
      return EDealershipTemplateFieldType.AccessoryName1;
    case 84:
    case "AccessoryVendor1":
      return EDealershipTemplateFieldType.AccessoryVendor1;
    case 85:
    case "AccessoryPrice1":
      return EDealershipTemplateFieldType.AccessoryPrice1;
    case 86:
    case "AccessoryName2":
      return EDealershipTemplateFieldType.AccessoryName2;
    case 87:
    case "AccessoryVendor2":
      return EDealershipTemplateFieldType.AccessoryVendor2;
    case 88:
    case "AccessoryPrice2":
      return EDealershipTemplateFieldType.AccessoryPrice2;
    case 89:
    case "AccessoryName3":
      return EDealershipTemplateFieldType.AccessoryName3;
    case 90:
    case "AccessoryVendor3":
      return EDealershipTemplateFieldType.AccessoryVendor3;
    case 91:
    case "AccessoryPrice3":
      return EDealershipTemplateFieldType.AccessoryPrice3;
    case 92:
    case "AccessoryName4":
      return EDealershipTemplateFieldType.AccessoryName4;
    case 93:
    case "AccessoryVendor4":
      return EDealershipTemplateFieldType.AccessoryVendor4;
    case 94:
    case "AccessoryPrice4":
      return EDealershipTemplateFieldType.AccessoryPrice4;
    case 95:
    case "AccessoryName5":
      return EDealershipTemplateFieldType.AccessoryName5;
    case 96:
    case "AccessoryVendor5":
      return EDealershipTemplateFieldType.AccessoryVendor5;
    case 97:
    case "AccessoryPrice5":
      return EDealershipTemplateFieldType.AccessoryPrice5;
    case 98:
    case "AccessoryName6":
      return EDealershipTemplateFieldType.AccessoryName6;
    case 99:
    case "AccessoryVendor6":
      return EDealershipTemplateFieldType.AccessoryVendor6;
    case 100:
    case "AccessoryPrice6":
      return EDealershipTemplateFieldType.AccessoryPrice6;
    case 101:
    case "AccessoryName7":
      return EDealershipTemplateFieldType.AccessoryName7;
    case 102:
    case "AccessoryVendor7":
      return EDealershipTemplateFieldType.AccessoryVendor7;
    case 103:
    case "AccessoryPrice7":
      return EDealershipTemplateFieldType.AccessoryPrice7;
    case 104:
    case "AccessoryName8":
      return EDealershipTemplateFieldType.AccessoryName8;
    case 105:
    case "AccessoryVendor8":
      return EDealershipTemplateFieldType.AccessoryVendor8;
    case 106:
    case "AccessoryPrice8":
      return EDealershipTemplateFieldType.AccessoryPrice8;
    case 107:
    case "AccessoryName9":
      return EDealershipTemplateFieldType.AccessoryName9;
    case 108:
    case "AccessoryVendor9":
      return EDealershipTemplateFieldType.AccessoryVendor9;
    case 109:
    case "AccessoryPrice9":
      return EDealershipTemplateFieldType.AccessoryPrice9;
    case 110:
    case "AccessoryName10":
      return EDealershipTemplateFieldType.AccessoryName10;
    case 111:
    case "AccessoryVendor10":
      return EDealershipTemplateFieldType.AccessoryVendor10;
    case 112:
    case "AccessoryPrice10":
      return EDealershipTemplateFieldType.AccessoryPrice10;
    case 113:
    case "AccessoryName11":
      return EDealershipTemplateFieldType.AccessoryName11;
    case 114:
    case "AccessoryVendor11":
      return EDealershipTemplateFieldType.AccessoryVendor11;
    case 115:
    case "AccessoryPrice11":
      return EDealershipTemplateFieldType.AccessoryPrice11;
    case 116:
    case "AccessoryName12":
      return EDealershipTemplateFieldType.AccessoryName12;
    case 117:
    case "AccessoryVendor12":
      return EDealershipTemplateFieldType.AccessoryVendor12;
    case 118:
    case "AccessoryPrice12":
      return EDealershipTemplateFieldType.AccessoryPrice12;
    case 119:
    case "AccessoryName13":
      return EDealershipTemplateFieldType.AccessoryName13;
    case 120:
    case "AccessoryVendor13":
      return EDealershipTemplateFieldType.AccessoryVendor13;
    case 121:
    case "AccessoryPrice13":
      return EDealershipTemplateFieldType.AccessoryPrice13;
    case 122:
    case "AccessoryName14":
      return EDealershipTemplateFieldType.AccessoryName14;
    case 123:
    case "AccessoryVendor14":
      return EDealershipTemplateFieldType.AccessoryVendor14;
    case 124:
    case "AccessoryPrice14":
      return EDealershipTemplateFieldType.AccessoryPrice14;
    case 125:
    case "AccessoryName15":
      return EDealershipTemplateFieldType.AccessoryName15;
    case 126:
    case "AccessoryVendor15":
      return EDealershipTemplateFieldType.AccessoryVendor15;
    case 127:
    case "AccessoryPrice15":
      return EDealershipTemplateFieldType.AccessoryPrice15;
    case 128:
    case "ServiceContractDescription":
      return EDealershipTemplateFieldType.ServiceContractDescription;
    case 129:
    case "ServiceContractPrice":
      return EDealershipTemplateFieldType.ServiceContractPrice;
    case 130:
    case "BasePayment":
      return EDealershipTemplateFieldType.BasePayment;
    case 131:
    case "AccessoriesTotalPrice":
      return EDealershipTemplateFieldType.AccessoriesTotalPrice;
    case 132:
    case "InsuranceCompanyPhoneNumber":
      return EDealershipTemplateFieldType.InsuranceCompanyPhoneNumber;
    case 133:
    case "ProtectionProductName1":
      return EDealershipTemplateFieldType.ProtectionProductName1;
    case 134:
    case "ProtectionProductPrice1":
      return EDealershipTemplateFieldType.ProtectionProductPrice1;
    case 135:
    case "ProtectionProductDescription1":
      return EDealershipTemplateFieldType.ProtectionProductDescription1;
    case 136:
    case "ProtectionProductName2":
      return EDealershipTemplateFieldType.ProtectionProductName2;
    case 137:
    case "ProtectionProductPrice2":
      return EDealershipTemplateFieldType.ProtectionProductPrice2;
    case 138:
    case "ProtectionProductDescription2":
      return EDealershipTemplateFieldType.ProtectionProductDescription2;
    case 139:
    case "ProtectionProductName3":
      return EDealershipTemplateFieldType.ProtectionProductName3;
    case 140:
    case "ProtectionProductPrice3":
      return EDealershipTemplateFieldType.ProtectionProductPrice3;
    case 141:
    case "ProtectionProductDescription3":
      return EDealershipTemplateFieldType.ProtectionProductDescription3;
    case 142:
    case "ProtectionProductName4":
      return EDealershipTemplateFieldType.ProtectionProductName4;
    case 143:
    case "ProtectionProductPrice4":
      return EDealershipTemplateFieldType.ProtectionProductPrice4;
    case 144:
    case "ProtectionProductDescription4":
      return EDealershipTemplateFieldType.ProtectionProductDescription4;
    case 145:
    case "ProtectionProductName5":
      return EDealershipTemplateFieldType.ProtectionProductName5;
    case 146:
    case "ProtectionProductPrice5":
      return EDealershipTemplateFieldType.ProtectionProductPrice5;
    case 147:
    case "ProtectionProductDescription5":
      return EDealershipTemplateFieldType.ProtectionProductDescription5;
    case 148:
    case "ProtectionProductName6":
      return EDealershipTemplateFieldType.ProtectionProductName6;
    case 149:
    case "ProtectionProductPrice6":
      return EDealershipTemplateFieldType.ProtectionProductPrice6;
    case 150:
    case "ProtectionProductDescription6":
      return EDealershipTemplateFieldType.ProtectionProductDescription6;
    case 151:
    case "ProtectionProductName7":
      return EDealershipTemplateFieldType.ProtectionProductName7;
    case 152:
    case "ProtectionProductPrice7":
      return EDealershipTemplateFieldType.ProtectionProductPrice7;
    case 153:
    case "ProtectionProductDescription7":
      return EDealershipTemplateFieldType.ProtectionProductDescription7;
    case 154:
    case "ProtectionProductName8":
      return EDealershipTemplateFieldType.ProtectionProductName8;
    case 155:
    case "ProtectionProductPrice8":
      return EDealershipTemplateFieldType.ProtectionProductPrice8;
    case 156:
    case "ProtectionProductDescription8":
      return EDealershipTemplateFieldType.ProtectionProductDescription8;
    case 157:
    case "ProtectionProductName9":
      return EDealershipTemplateFieldType.ProtectionProductName9;
    case 158:
    case "ProtectionProductPrice9":
      return EDealershipTemplateFieldType.ProtectionProductPrice9;
    case 159:
    case "ProtectionProductDescription9":
      return EDealershipTemplateFieldType.ProtectionProductDescription9;
    case 160:
    case "ProtectionProductName10":
      return EDealershipTemplateFieldType.ProtectionProductName10;
    case 161:
    case "ProtectionProductPrice10":
      return EDealershipTemplateFieldType.ProtectionProductPrice10;
    case 162:
    case "ProtectionProductDescription10":
      return EDealershipTemplateFieldType.ProtectionProductDescription10;
    case 163:
    case "Common_Shared_FreeText":
      return EDealershipTemplateFieldType.Common_Shared_FreeText;
    case 164:
    case "Common_Shared_CheckBox":
      return EDealershipTemplateFieldType.Common_Shared_CheckBox;
    case 165:
    case "AllProtectionProductsPrice":
      return EDealershipTemplateFieldType.AllProtectionProductsPrice;
    case 166:
    case "TotalFees":
      return EDealershipTemplateFieldType.TotalFees;
    case 167:
    case "TotalRebates":
      return EDealershipTemplateFieldType.TotalRebates;
    case 168:
    case "SecondTradeVehicleTrim":
      return EDealershipTemplateFieldType.SecondTradeVehicleTrim;
    case 169:
    case "SecondTradeVehicleMake":
      return EDealershipTemplateFieldType.SecondTradeVehicleMake;
    case 170:
    case "SecondTradeVehicleModel":
      return EDealershipTemplateFieldType.SecondTradeVehicleModel;
    case 171:
    case "SecondTradeVehicleYear":
      return EDealershipTemplateFieldType.SecondTradeVehicleYear;
    case 172:
    case "SecondTradeVehicleVin":
      return EDealershipTemplateFieldType.SecondTradeVehicleVin;
    case 173:
    case "SecondTradeVehicleMileage":
      return EDealershipTemplateFieldType.SecondTradeVehicleMileage;
    case 174:
    case "LienHolderCity":
      return EDealershipTemplateFieldType.LienHolderCity;
    case 175:
    case "LienHolderState":
      return EDealershipTemplateFieldType.LienHolderState;
    case 176:
    case "LienHolderZip":
      return EDealershipTemplateFieldType.LienHolderZip;
    case 177:
    case "LienHolderStreet":
      return EDealershipTemplateFieldType.LienHolderStreet;
    case 178:
    case "TradeLienHolderName":
      return EDealershipTemplateFieldType.TradeLienHolderName;
    case 179:
    case "TradeLienHolderCity":
      return EDealershipTemplateFieldType.TradeLienHolderCity;
    case 180:
    case "TradeLienHolderState":
      return EDealershipTemplateFieldType.TradeLienHolderState;
    case 181:
    case "TradeLienHolderZip":
      return EDealershipTemplateFieldType.TradeLienHolderZip;
    case 182:
    case "TradeLienHolderStreet":
      return EDealershipTemplateFieldType.TradeLienHolderStreet;
    case 183:
    case "PolicyHolderName":
      return EDealershipTemplateFieldType.PolicyHolderName;
    case 184:
    case "PolicyHolderCity":
      return EDealershipTemplateFieldType.PolicyHolderCity;
    case 185:
    case "PolicyHolderState":
      return EDealershipTemplateFieldType.PolicyHolderState;
    case 186:
    case "PolicyHolderZip":
      return EDealershipTemplateFieldType.PolicyHolderZip;
    case 187:
    case "PolicyHolderStreet":
      return EDealershipTemplateFieldType.PolicyHolderStreet;
    case 188:
    case "Payment":
      return EDealershipTemplateFieldType.Payment;
    case 189:
    case "TaxRate":
      return EDealershipTemplateFieldType.TaxRate;
    case 190:
    case "TotalFinanceAmount":
      return EDealershipTemplateFieldType.TotalFinanceAmount;
    case 191:
    case "DealType":
      return EDealershipTemplateFieldType.DealType;
    case 192:
    case "TradeValue":
      return EDealershipTemplateFieldType.TradeValue;
    case 193:
    case "SecondTradeValue":
      return EDealershipTemplateFieldType.SecondTradeValue;
    case 194:
    case "TradeNetValue":
      return EDealershipTemplateFieldType.TradeNetValue;
    case 195:
    case "SecondTradeNetValue":
      return EDealershipTemplateFieldType.SecondTradeNetValue;
    case 196:
    case "TotalTaxes":
      return EDealershipTemplateFieldType.TotalTaxes;
    case 197:
    case "TaxableFieldName1":
      return EDealershipTemplateFieldType.TaxableFieldName1;
    case 198:
    case "TaxableFieldValue1":
      return EDealershipTemplateFieldType.TaxableFieldValue1;
    case 199:
    case "TaxableFieldName2":
      return EDealershipTemplateFieldType.TaxableFieldName2;
    case 200:
    case "TaxableFieldValue2":
      return EDealershipTemplateFieldType.TaxableFieldValue2;
    case 201:
    case "TaxableFieldName3":
      return EDealershipTemplateFieldType.TaxableFieldName3;
    case 202:
    case "TaxableFieldValue3":
      return EDealershipTemplateFieldType.TaxableFieldValue3;
    case 203:
    case "TaxableFieldName4":
      return EDealershipTemplateFieldType.TaxableFieldName4;
    case 204:
    case "TaxableFieldValue4":
      return EDealershipTemplateFieldType.TaxableFieldValue4;
    case 205:
    case "TaxableFieldName5":
      return EDealershipTemplateFieldType.TaxableFieldName5;
    case 206:
    case "TaxableFieldValue5":
      return EDealershipTemplateFieldType.TaxableFieldValue5;
    case 207:
    case "TaxableFieldName6":
      return EDealershipTemplateFieldType.TaxableFieldName6;
    case 208:
    case "TaxableFieldValue6":
      return EDealershipTemplateFieldType.TaxableFieldValue6;
    case 209:
    case "TaxableFieldName7":
      return EDealershipTemplateFieldType.TaxableFieldName7;
    case 210:
    case "TaxableFieldValue7":
      return EDealershipTemplateFieldType.TaxableFieldValue7;
    case 211:
    case "TaxableFieldName8":
      return EDealershipTemplateFieldType.TaxableFieldName8;
    case 212:
    case "TaxableFieldValue8":
      return EDealershipTemplateFieldType.TaxableFieldValue8;
    case 213:
    case "TaxableFieldName9":
      return EDealershipTemplateFieldType.TaxableFieldName9;
    case 214:
    case "TaxableFieldValue9":
      return EDealershipTemplateFieldType.TaxableFieldValue9;
    case 215:
    case "TaxableFieldName10":
      return EDealershipTemplateFieldType.TaxableFieldName10;
    case 216:
    case "TaxableFieldValue10":
      return EDealershipTemplateFieldType.TaxableFieldValue10;
    case 217:
    case "TaxableFieldName11":
      return EDealershipTemplateFieldType.TaxableFieldName11;
    case 218:
    case "TaxableFieldValue11":
      return EDealershipTemplateFieldType.TaxableFieldValue11;
    case 219:
    case "TaxableFieldName12":
      return EDealershipTemplateFieldType.TaxableFieldName12;
    case 220:
    case "TaxableFieldValue12":
      return EDealershipTemplateFieldType.TaxableFieldValue12;
    case 221:
    case "TaxableFieldName13":
      return EDealershipTemplateFieldType.TaxableFieldName13;
    case 222:
    case "TaxableFieldValue13":
      return EDealershipTemplateFieldType.TaxableFieldValue13;
    case 223:
    case "TaxableFieldName14":
      return EDealershipTemplateFieldType.TaxableFieldName14;
    case 224:
    case "TaxableFieldValue14":
      return EDealershipTemplateFieldType.TaxableFieldValue14;
    case 225:
    case "TaxableFieldName15":
      return EDealershipTemplateFieldType.TaxableFieldName15;
    case 226:
    case "TaxableFieldValue15":
      return EDealershipTemplateFieldType.TaxableFieldValue15;
    case 227:
    case "NonTaxableFeeName1":
      return EDealershipTemplateFieldType.NonTaxableFeeName1;
    case 228:
    case "NonTaxableFeeValue1":
      return EDealershipTemplateFieldType.NonTaxableFeeValue1;
    case 229:
    case "NonTaxableFeeName2":
      return EDealershipTemplateFieldType.NonTaxableFeeName2;
    case 230:
    case "NonTaxableFeeValue2":
      return EDealershipTemplateFieldType.NonTaxableFeeValue2;
    case 231:
    case "NonTaxableFeeName3":
      return EDealershipTemplateFieldType.NonTaxableFeeName3;
    case 232:
    case "NonTaxableFeeValue3":
      return EDealershipTemplateFieldType.NonTaxableFeeValue3;
    case 233:
    case "NonTaxableFeeName4":
      return EDealershipTemplateFieldType.NonTaxableFeeName4;
    case 234:
    case "NonTaxableFeeValue4":
      return EDealershipTemplateFieldType.NonTaxableFeeValue4;
    case 235:
    case "NonTaxableFeeName5":
      return EDealershipTemplateFieldType.NonTaxableFeeName5;
    case 236:
    case "NonTaxableFeeValue5":
      return EDealershipTemplateFieldType.NonTaxableFeeValue5;
    case 237:
    case "NonTaxableFeeName6":
      return EDealershipTemplateFieldType.NonTaxableFeeName6;
    case 238:
    case "NonTaxableFeeValue6":
      return EDealershipTemplateFieldType.NonTaxableFeeValue6;
    case 239:
    case "NonTaxableFeeName7":
      return EDealershipTemplateFieldType.NonTaxableFeeName7;
    case 240:
    case "NonTaxableFeeValue7":
      return EDealershipTemplateFieldType.NonTaxableFeeValue7;
    case 241:
    case "NonTaxableFeeName8":
      return EDealershipTemplateFieldType.NonTaxableFeeName8;
    case 242:
    case "NonTaxableFeeValue8":
      return EDealershipTemplateFieldType.NonTaxableFeeValue8;
    case 243:
    case "NonTaxableFeeName9":
      return EDealershipTemplateFieldType.NonTaxableFeeName9;
    case 244:
    case "NonTaxableFeeValue9":
      return EDealershipTemplateFieldType.NonTaxableFeeValue9;
    case 245:
    case "NonTaxableFeeName10":
      return EDealershipTemplateFieldType.NonTaxableFeeName10;
    case 246:
    case "NonTaxableFeeValue10":
      return EDealershipTemplateFieldType.NonTaxableFeeValue10;
    case 247:
    case "NonTaxableFeeName11":
      return EDealershipTemplateFieldType.NonTaxableFeeName11;
    case 248:
    case "NonTaxableFeeValue11":
      return EDealershipTemplateFieldType.NonTaxableFeeValue11;
    case 249:
    case "NonTaxableFeeName12":
      return EDealershipTemplateFieldType.NonTaxableFeeName12;
    case 250:
    case "NonTaxableFeeValue12":
      return EDealershipTemplateFieldType.NonTaxableFeeValue12;
    case 251:
    case "NonTaxableFeeName13":
      return EDealershipTemplateFieldType.NonTaxableFeeName13;
    case 252:
    case "NonTaxableFeeValue13":
      return EDealershipTemplateFieldType.NonTaxableFeeValue13;
    case 253:
    case "NonTaxableFeeName14":
      return EDealershipTemplateFieldType.NonTaxableFeeName14;
    case 254:
    case "NonTaxableFeeValue14":
      return EDealershipTemplateFieldType.NonTaxableFeeValue14;
    case 255:
    case "NonTaxableFeeName15":
      return EDealershipTemplateFieldType.NonTaxableFeeName15;
    case 256:
    case "NonTaxableFeeValue15":
      return EDealershipTemplateFieldType.NonTaxableFeeValue15;
    case 257:
    case "CancelableProductName1":
      return EDealershipTemplateFieldType.CancelableProductName1;
    case 258:
    case "CancelableProductPrice1":
      return EDealershipTemplateFieldType.CancelableProductPrice1;
    case 259:
    case "CancelableProductName2":
      return EDealershipTemplateFieldType.CancelableProductName2;
    case 260:
    case "CancelableProductPrice2":
      return EDealershipTemplateFieldType.CancelableProductPrice2;
    case 261:
    case "CancelableProductName3":
      return EDealershipTemplateFieldType.CancelableProductName3;
    case 262:
    case "CancelableProductPrice3":
      return EDealershipTemplateFieldType.CancelableProductPrice3;
    case 263:
    case "CancelableProductName4":
      return EDealershipTemplateFieldType.CancelableProductName4;
    case 264:
    case "CancelableProductPrice4":
      return EDealershipTemplateFieldType.CancelableProductPrice4;
    case 265:
    case "CancelableProductName5":
      return EDealershipTemplateFieldType.CancelableProductName5;
    case 266:
    case "CancelableProductPrice5":
      return EDealershipTemplateFieldType.CancelableProductPrice5;
    case 267:
    case "CancelableProductName6":
      return EDealershipTemplateFieldType.CancelableProductName6;
    case 268:
    case "CancelableProductPrice6":
      return EDealershipTemplateFieldType.CancelableProductPrice6;
    case 269:
    case "CancelableProductName7":
      return EDealershipTemplateFieldType.CancelableProductName7;
    case 270:
    case "CancelableProductPrice7":
      return EDealershipTemplateFieldType.CancelableProductPrice7;
    case 271:
    case "CancelableProductName8":
      return EDealershipTemplateFieldType.CancelableProductName8;
    case 272:
    case "CancelableProductPrice8":
      return EDealershipTemplateFieldType.CancelableProductPrice8;
    case 273:
    case "CancelableProductName9":
      return EDealershipTemplateFieldType.CancelableProductName9;
    case 274:
    case "CancelableProductPrice9":
      return EDealershipTemplateFieldType.CancelableProductPrice9;
    case 275:
    case "CancelableProductName10":
      return EDealershipTemplateFieldType.CancelableProductName10;
    case 276:
    case "CancelableProductPrice10":
      return EDealershipTemplateFieldType.CancelableProductPrice10;
    case 277:
    case "CancelableProductName11":
      return EDealershipTemplateFieldType.CancelableProductName11;
    case 278:
    case "CancelableProductPrice11":
      return EDealershipTemplateFieldType.CancelableProductPrice11;
    case 279:
    case "CancelableProductName12":
      return EDealershipTemplateFieldType.CancelableProductName12;
    case 280:
    case "CancelableProductPrice12":
      return EDealershipTemplateFieldType.CancelableProductPrice12;
    case 281:
    case "CancelableProductName13":
      return EDealershipTemplateFieldType.CancelableProductName13;
    case 282:
    case "CancelableProductPrice13":
      return EDealershipTemplateFieldType.CancelableProductPrice13;
    case 283:
    case "CancelableProductName14":
      return EDealershipTemplateFieldType.CancelableProductName14;
    case 284:
    case "CancelableProductPrice14":
      return EDealershipTemplateFieldType.CancelableProductPrice14;
    case 285:
    case "CancelableProductName15":
      return EDealershipTemplateFieldType.CancelableProductName15;
    case 286:
    case "CancelableProductPrice15":
      return EDealershipTemplateFieldType.CancelableProductPrice15;
    case 287:
    case "TaxableSubTotal":
      return EDealershipTemplateFieldType.TaxableSubTotal;
    case 288:
    case "Subtotal":
      return EDealershipTemplateFieldType.Subtotal;
    case 289:
    case "TotalFeesWithoutDoc":
      return EDealershipTemplateFieldType.TotalFeesWithoutDoc;
    case 290:
    case "TotalTradesValue":
      return EDealershipTemplateFieldType.TotalTradesValue;
    case 291:
    case "DoingBusinessAs":
      return EDealershipTemplateFieldType.DoingBusinessAs;
    case 292:
    case "EmployerIdentificationNumber":
      return EDealershipTemplateFieldType.EmployerIdentificationNumber;
    case 293:
    case "TradeDifference":
      return EDealershipTemplateFieldType.TradeDifference;
    case 294:
    case "ProductName":
      return EDealershipTemplateFieldType.ProductName;
    case 295:
    case "ProductPrice":
      return EDealershipTemplateFieldType.ProductPrice;
    case 296:
    case "ProductMileage":
      return EDealershipTemplateFieldType.ProductMileage;
    case 297:
    case "ProductTerm":
      return EDealershipTemplateFieldType.ProductTerm;
    case 298:
    case "Customer_Shared_CreditScore":
      return EDealershipTemplateFieldType.Customer_Shared_CreditScore;
    case 299:
    case "Customer_Shared_FirstName":
      return EDealershipTemplateFieldType.Customer_Shared_FirstName;
    case 300:
    case "Customer_Shared_LastName":
      return EDealershipTemplateFieldType.Customer_Shared_LastName;
    case 301:
    case "Customer_Shared_MiddleName":
      return EDealershipTemplateFieldType.Customer_Shared_MiddleName;
    case 302:
    case "Customer_Shared_DateOfBirth":
      return EDealershipTemplateFieldType.Customer_Shared_DateOfBirth;
    case 303:
    case "Customer_Shared_Initials":
      return EDealershipTemplateFieldType.Customer_Shared_Initials;
    case 304:
    case "Customer_Shared_PhoneNumber":
      return EDealershipTemplateFieldType.Customer_Shared_PhoneNumber;
    case 305:
    case "Customer_Shared_SecondaryPhone":
      return EDealershipTemplateFieldType.Customer_Shared_SecondaryPhone;
    case 306:
    case "Customer_Shared_Email":
      return EDealershipTemplateFieldType.Customer_Shared_Email;
    case 307:
    case "Customer_Shared_Ssn":
      return EDealershipTemplateFieldType.Customer_Shared_Ssn;
    case 308:
    case "Customer_Shared_StreetAndNumber":
      return EDealershipTemplateFieldType.Customer_Shared_StreetAndNumber;
    case 309:
    case "Customer_Shared_City":
      return EDealershipTemplateFieldType.Customer_Shared_City;
    case 310:
    case "Customer_Shared_State":
      return EDealershipTemplateFieldType.Customer_Shared_State;
    case 311:
    case "Customer_Shared_Zip":
      return EDealershipTemplateFieldType.Customer_Shared_Zip;
    case 312:
    case "Customer_Shared_County":
      return EDealershipTemplateFieldType.Customer_Shared_County;
    case 313:
    case "Customer_Shared_DriverLicenseNumber":
      return EDealershipTemplateFieldType.Customer_Shared_DriverLicenseNumber;
    case 314:
    case "Customer_Shared_PreviousStreetAndNumber":
      return EDealershipTemplateFieldType.Customer_Shared_PreviousStreetAndNumber;
    case 315:
    case "Customer_Shared_PreviousCity":
      return EDealershipTemplateFieldType.Customer_Shared_PreviousCity;
    case 316:
    case "Customer_Shared_PreviousState":
      return EDealershipTemplateFieldType.Customer_Shared_PreviousState;
    case 317:
    case "Customer_Shared_PreviousZip":
      return EDealershipTemplateFieldType.Customer_Shared_PreviousZip;
    case 318:
    case "Customer_Shared_PreviousCounty":
      return EDealershipTemplateFieldType.Customer_Shared_PreviousCounty;
    case 319:
    case "Customer_Shared_TimeAtPreviousResidence":
      return EDealershipTemplateFieldType.Customer_Shared_TimeAtPreviousResidence;
    case 320:
    case "Customer_Shared_ResidenceType":
      return EDealershipTemplateFieldType.Customer_Shared_ResidenceType;
    case 321:
    case "Customer_Shared_AddressLine2":
      return EDealershipTemplateFieldType.Customer_Shared_AddressLine2;
    case 322:
    case "Customer_Shared_RentMortgagePayment":
      return EDealershipTemplateFieldType.Customer_Shared_RentMortgagePayment;
    case 323:
    case "Customer_Shared_TimeAtResidence":
      return EDealershipTemplateFieldType.Customer_Shared_TimeAtResidence;
    case 324:
    case "Customer_Shared_JobTitle":
      return EDealershipTemplateFieldType.Customer_Shared_JobTitle;
    case 325:
    case "Customer_Shared_Employer":
      return EDealershipTemplateFieldType.Customer_Shared_Employer;
    case 326:
    case "Customer_Shared_Income":
      return EDealershipTemplateFieldType.Customer_Shared_Income;
    case 327:
    case "Customer_Shared_OtherIncome":
      return EDealershipTemplateFieldType.Customer_Shared_OtherIncome;
    case 328:
    case "Customer_Shared_PreviousEmployee":
      return EDealershipTemplateFieldType.Customer_Shared_PreviousEmployee;
    case 329:
    case "Customer_Shared_EmployerPhone":
      return EDealershipTemplateFieldType.Customer_Shared_EmployerPhone;
    case 330:
    case "Customer_Shared_EmploymentStatus":
      return EDealershipTemplateFieldType.Customer_Shared_EmploymentStatus;
    case 331:
    case "Customer_Shared_TimeAtCurrentJob":
      return EDealershipTemplateFieldType.Customer_Shared_TimeAtCurrentJob;
    case 332:
    case "Customer_Shared_YearsOfEmployment":
      return EDealershipTemplateFieldType.Customer_Shared_YearsOfEmployment;
    case 333:
    case "Customer_Shared_MonthsOfEmployment":
      return EDealershipTemplateFieldType.Customer_Shared_MonthsOfEmployment;
    case 334:
    case "Customer_Shared_Date":
      return EDealershipTemplateFieldType.Customer_Shared_Date;
    case 335:
    case "Customer_Shared_Signature":
      return EDealershipTemplateFieldType.Customer_Shared_Signature;
    case 336:
    case "Date":
      return EDealershipTemplateFieldType.Date;
    case 337:
    case "TradeAccountNumber":
      return EDealershipTemplateFieldType.TradeAccountNumber;
    case 339:
    case "TradePayoffGoodUntilDate":
      return EDealershipTemplateFieldType.TradePayoffGoodUntilDate;
    case 340:
    case "TradePerDiem":
      return EDealershipTemplateFieldType.TradePerDiem;
    case 341:
    case "Customer_Shared_FullName":
      return EDealershipTemplateFieldType.Customer_Shared_FullName;
    case 342:
    case "Customer_Shared_FullAddress":
      return EDealershipTemplateFieldType.Customer_Shared_FullAddress;
    case 343:
    case "NonCancelableProductName1":
      return EDealershipTemplateFieldType.NonCancelableProductName1;
    case 344:
    case "NonCancelableProductPrice1":
      return EDealershipTemplateFieldType.NonCancelableProductPrice1;
    case 345:
    case "NonCancelableProductName2":
      return EDealershipTemplateFieldType.NonCancelableProductName2;
    case 346:
    case "NonCancelableProductPrice2":
      return EDealershipTemplateFieldType.NonCancelableProductPrice2;
    case 347:
    case "NonCancelableProductName3":
      return EDealershipTemplateFieldType.NonCancelableProductName3;
    case 348:
    case "NonCancelableProductPrice3":
      return EDealershipTemplateFieldType.NonCancelableProductPrice3;
    case 349:
    case "NonCancelableProductName4":
      return EDealershipTemplateFieldType.NonCancelableProductName4;
    case 350:
    case "NonCancelableProductPrice4":
      return EDealershipTemplateFieldType.NonCancelableProductPrice4;
    case 351:
    case "NonCancelableProductName5":
      return EDealershipTemplateFieldType.NonCancelableProductName5;
    case 352:
    case "NonCancelableProductPrice5":
      return EDealershipTemplateFieldType.NonCancelableProductPrice5;
    case 353:
    case "NonCancelableProductName6":
      return EDealershipTemplateFieldType.NonCancelableProductName6;
    case 354:
    case "NonCancelableProductPrice6":
      return EDealershipTemplateFieldType.NonCancelableProductPrice6;
    case 355:
    case "NonCancelableProductName7":
      return EDealershipTemplateFieldType.NonCancelableProductName7;
    case 356:
    case "NonCancelableProductPrice7":
      return EDealershipTemplateFieldType.NonCancelableProductPrice7;
    case 357:
    case "NonCancelableProductName8":
      return EDealershipTemplateFieldType.NonCancelableProductName8;
    case 358:
    case "NonCancelableProductPrice8":
      return EDealershipTemplateFieldType.NonCancelableProductPrice8;
    case 359:
    case "NonCancelableProductName9":
      return EDealershipTemplateFieldType.NonCancelableProductName9;
    case 360:
    case "NonCancelableProductPrice9":
      return EDealershipTemplateFieldType.NonCancelableProductPrice9;
    case 361:
    case "NonCancelableProductName10":
      return EDealershipTemplateFieldType.NonCancelableProductName10;
    case 362:
    case "NonCancelableProductPrice10":
      return EDealershipTemplateFieldType.NonCancelableProductPrice10;
    case 363:
    case "NonCancelableProductName11":
      return EDealershipTemplateFieldType.NonCancelableProductName11;
    case 364:
    case "NonCancelableProductPrice11":
      return EDealershipTemplateFieldType.NonCancelableProductPrice11;
    case 365:
    case "NonCancelableProductName12":
      return EDealershipTemplateFieldType.NonCancelableProductName12;
    case 366:
    case "NonCancelableProductPrice12":
      return EDealershipTemplateFieldType.NonCancelableProductPrice12;
    case 367:
    case "NonCancelableProductName13":
      return EDealershipTemplateFieldType.NonCancelableProductName13;
    case 368:
    case "NonCancelableProductPrice13":
      return EDealershipTemplateFieldType.NonCancelableProductPrice13;
    case 369:
    case "NonCancelableProductName14":
      return EDealershipTemplateFieldType.NonCancelableProductName14;
    case 370:
    case "NonCancelableProductPrice14":
      return EDealershipTemplateFieldType.NonCancelableProductPrice14;
    case 371:
    case "NonCancelableProductName15":
      return EDealershipTemplateFieldType.NonCancelableProductName15;
    case 372:
    case "NonCancelableProductPrice15":
      return EDealershipTemplateFieldType.NonCancelableProductPrice15;
    case 373:
    case "TotalFrontEndPrice":
      return EDealershipTemplateFieldType.TotalFrontEndPrice;
    case 374:
    case "VehicleTaxableAmount":
      return EDealershipTemplateFieldType.VehicleTaxableAmount;
    case 375:
    case "VehicleTotalAmount":
      return EDealershipTemplateFieldType.VehicleTotalAmount;
    case 376:
    case "VehicleTotalAmountWithoutPayoff":
      return EDealershipTemplateFieldType.VehicleTotalAmountWithoutPayoff;
    case 377:
    case "SellingPrice":
      return EDealershipTemplateFieldType.SellingPrice;
    case 378:
    case "SalesPersonEmail":
      return EDealershipTemplateFieldType.SalesPersonEmail;
    case 379:
    case "APR":
      return EDealershipTemplateFieldType.APR;
    case 380:
    case "DeclinedProductName1":
      return EDealershipTemplateFieldType.DeclinedProductName1;
    case 381:
    case "DeclinedProductMonthlyPrice1":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice1;
    case 382:
    case "DeclinedProductPrice1":
      return EDealershipTemplateFieldType.DeclinedProductPrice1;
    case 383:
    case "DeclinedProductTerm1":
      return EDealershipTemplateFieldType.DeclinedProductTerm1;
    case 384:
    case "DeclinedProductMileage1":
      return EDealershipTemplateFieldType.DeclinedProductMileage1;
    case 385:
    case "DeclinedProductName2":
      return EDealershipTemplateFieldType.DeclinedProductName2;
    case 386:
    case "DeclinedProductMonthlyPrice2":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice2;
    case 387:
    case "DeclinedProductPrice2":
      return EDealershipTemplateFieldType.DeclinedProductPrice2;
    case 388:
    case "DeclinedProductTerm2":
      return EDealershipTemplateFieldType.DeclinedProductTerm2;
    case 389:
    case "DeclinedProductMileage2":
      return EDealershipTemplateFieldType.DeclinedProductMileage2;
    case 390:
    case "DeclinedProductName3":
      return EDealershipTemplateFieldType.DeclinedProductName3;
    case 391:
    case "DeclinedProductMonthlyPrice3":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice3;
    case 392:
    case "DeclinedProductPrice3":
      return EDealershipTemplateFieldType.DeclinedProductPrice3;
    case 393:
    case "DeclinedProductTerm3":
      return EDealershipTemplateFieldType.DeclinedProductTerm3;
    case 394:
    case "DeclinedProductMileage3":
      return EDealershipTemplateFieldType.DeclinedProductMileage3;
    case 395:
    case "DeclinedProductName4":
      return EDealershipTemplateFieldType.DeclinedProductName4;
    case 396:
    case "DeclinedProductMonthlyPrice4":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice4;
    case 397:
    case "DeclinedProductPrice4":
      return EDealershipTemplateFieldType.DeclinedProductPrice4;
    case 398:
    case "DeclinedProductTerm4":
      return EDealershipTemplateFieldType.DeclinedProductTerm4;
    case 399:
    case "DeclinedProductMileage4":
      return EDealershipTemplateFieldType.DeclinedProductMileage4;
    case 400:
    case "DeclinedProductName5":
      return EDealershipTemplateFieldType.DeclinedProductName5;
    case 401:
    case "DeclinedProductMonthlyPrice5":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice5;
    case 402:
    case "DeclinedProductPrice5":
      return EDealershipTemplateFieldType.DeclinedProductPrice5;
    case 403:
    case "DeclinedProductTerm5":
      return EDealershipTemplateFieldType.DeclinedProductTerm5;
    case 404:
    case "DeclinedProductMileage5":
      return EDealershipTemplateFieldType.DeclinedProductMileage5;
    case 405:
    case "DeclinedProductName6":
      return EDealershipTemplateFieldType.DeclinedProductName6;
    case 406:
    case "DeclinedProductMonthlyPrice6":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice6;
    case 407:
    case "DeclinedProductPrice6":
      return EDealershipTemplateFieldType.DeclinedProductPrice6;
    case 408:
    case "DeclinedProductTerm6":
      return EDealershipTemplateFieldType.DeclinedProductTerm6;
    case 409:
    case "DeclinedProductMileage6":
      return EDealershipTemplateFieldType.DeclinedProductMileage6;
    case 410:
    case "DeclinedProductName7":
      return EDealershipTemplateFieldType.DeclinedProductName7;
    case 411:
    case "DeclinedProductMonthlyPrice7":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice7;
    case 412:
    case "DeclinedProductPrice7":
      return EDealershipTemplateFieldType.DeclinedProductPrice7;
    case 413:
    case "DeclinedProductTerm7":
      return EDealershipTemplateFieldType.DeclinedProductTerm7;
    case 414:
    case "DeclinedProductMileage7":
      return EDealershipTemplateFieldType.DeclinedProductMileage7;
    case 415:
    case "DeclinedProductName8":
      return EDealershipTemplateFieldType.DeclinedProductName8;
    case 416:
    case "DeclinedProductMonthlyPrice8":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice8;
    case 417:
    case "DeclinedProductPrice8":
      return EDealershipTemplateFieldType.DeclinedProductPrice8;
    case 418:
    case "DeclinedProductTerm8":
      return EDealershipTemplateFieldType.DeclinedProductTerm8;
    case 419:
    case "DeclinedProductMileage8":
      return EDealershipTemplateFieldType.DeclinedProductMileage8;
    case 420:
    case "DeclinedProductName9":
      return EDealershipTemplateFieldType.DeclinedProductName9;
    case 421:
    case "DeclinedProductMonthlyPrice9":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice9;
    case 422:
    case "DeclinedProductPrice9":
      return EDealershipTemplateFieldType.DeclinedProductPrice9;
    case 423:
    case "DeclinedProductTerm9":
      return EDealershipTemplateFieldType.DeclinedProductTerm9;
    case 424:
    case "DeclinedProductMileage9":
      return EDealershipTemplateFieldType.DeclinedProductMileage9;
    case 425:
    case "DeclinedProductName10":
      return EDealershipTemplateFieldType.DeclinedProductName10;
    case 426:
    case "DeclinedProductMonthlyPrice10":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice10;
    case 427:
    case "DeclinedProductPrice10":
      return EDealershipTemplateFieldType.DeclinedProductPrice10;
    case 428:
    case "DeclinedProductTerm10":
      return EDealershipTemplateFieldType.DeclinedProductTerm10;
    case 429:
    case "DeclinedProductMileage10":
      return EDealershipTemplateFieldType.DeclinedProductMileage10;
    case 430:
    case "DeclinedProductName11":
      return EDealershipTemplateFieldType.DeclinedProductName11;
    case 431:
    case "DeclinedProductMonthlyPrice11":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice11;
    case 432:
    case "DeclinedProductPrice11":
      return EDealershipTemplateFieldType.DeclinedProductPrice11;
    case 433:
    case "DeclinedProductTerm11":
      return EDealershipTemplateFieldType.DeclinedProductTerm11;
    case 434:
    case "DeclinedProductMileage11":
      return EDealershipTemplateFieldType.DeclinedProductMileage11;
    case 435:
    case "DeclinedProductName12":
      return EDealershipTemplateFieldType.DeclinedProductName12;
    case 436:
    case "DeclinedProductMonthlyPrice12":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice12;
    case 437:
    case "DeclinedProductPrice12":
      return EDealershipTemplateFieldType.DeclinedProductPrice12;
    case 438:
    case "DeclinedProductTerm12":
      return EDealershipTemplateFieldType.DeclinedProductTerm12;
    case 439:
    case "DeclinedProductMileage12":
      return EDealershipTemplateFieldType.DeclinedProductMileage12;
    case 440:
    case "DeclinedProductName13":
      return EDealershipTemplateFieldType.DeclinedProductName13;
    case 441:
    case "DeclinedProductMonthlyPrice13":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice13;
    case 442:
    case "DeclinedProductPrice13":
      return EDealershipTemplateFieldType.DeclinedProductPrice13;
    case 443:
    case "DeclinedProductTerm13":
      return EDealershipTemplateFieldType.DeclinedProductTerm13;
    case 444:
    case "DeclinedProductMileage13":
      return EDealershipTemplateFieldType.DeclinedProductMileage13;
    case 445:
    case "DeclinedProductName14":
      return EDealershipTemplateFieldType.DeclinedProductName14;
    case 446:
    case "DeclinedProductMonthlyPrice14":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice14;
    case 447:
    case "DeclinedProductPrice14":
      return EDealershipTemplateFieldType.DeclinedProductPrice14;
    case 448:
    case "DeclinedProductTerm14":
      return EDealershipTemplateFieldType.DeclinedProductTerm14;
    case 449:
    case "DeclinedProductMileage14":
      return EDealershipTemplateFieldType.DeclinedProductMileage14;
    case 450:
    case "DeclinedProductName15":
      return EDealershipTemplateFieldType.DeclinedProductName15;
    case 451:
    case "DeclinedProductMonthlyPrice15":
      return EDealershipTemplateFieldType.DeclinedProductMonthlyPrice15;
    case 452:
    case "DeclinedProductPrice15":
      return EDealershipTemplateFieldType.DeclinedProductPrice15;
    case 453:
    case "DeclinedProductTerm15":
      return EDealershipTemplateFieldType.DeclinedProductTerm15;
    case 454:
    case "DeclinedProductMileage15":
      return EDealershipTemplateFieldType.DeclinedProductMileage15;
    case 455:
    case "SelectedTerm":
      return EDealershipTemplateFieldType.SelectedTerm;
    case 456:
    case "Customer_Shared_PreviousFullAddress":
      return EDealershipTemplateFieldType.Customer_Shared_PreviousFullAddress;
    case 457:
    case "Customer_Shared_IncomeInterval":
      return EDealershipTemplateFieldType.Customer_Shared_IncomeInterval;
    case 458:
    case "VehicleFuelType":
      return EDealershipTemplateFieldType.VehicleFuelType;
    case 459:
    case "TotalMileageAtLeaseEnd":
      return EDealershipTemplateFieldType.TotalMileageAtLeaseEnd;
    case 460:
    case "PaymentScheduleType":
      return EDealershipTemplateFieldType.PaymentScheduleType;
    case 461:
    case "GstTax":
      return EDealershipTemplateFieldType.GstTax;
    case 462:
    case "PstTax":
      return EDealershipTemplateFieldType.PstTax;
    case 463:
    case "LuxuryTax":
      return EDealershipTemplateFieldType.LuxuryTax;
    case 464:
    case "NumberOfPayments":
      return EDealershipTemplateFieldType.NumberOfPayments;
    case 465:
    case "VehicleInteriorColor":
      return EDealershipTemplateFieldType.VehicleInteriorColor;
    case 466:
    case "AcquisitionFee":
      return EDealershipTemplateFieldType.AcquisitionFee;
    case 467:
    case "TradeColor":
      return EDealershipTemplateFieldType.TradeColor;
    case 468:
    case "SecondTradeColor":
      return EDealershipTemplateFieldType.SecondTradeColor;
    case 469:
    case "SecondTradePayOff":
      return EDealershipTemplateFieldType.SecondTradePayOff;
    case 470:
    case "LeaseMileage":
      return EDealershipTemplateFieldType.LeaseMileage;
    case 471:
    case "Customer_Shared_DriverLicenseState":
      return EDealershipTemplateFieldType.Customer_Shared_DriverLicenseState;
    case 472:
    case "ServiceContractTermInYears":
      return EDealershipTemplateFieldType.ServiceContractTermInYears;
    case 473:
    case "ServiceContractMileage":
      return EDealershipTemplateFieldType.ServiceContractMileage;
    case 474:
    case "CapitalizedFeeName1":
      return EDealershipTemplateFieldType.CapitalizedFeeName1;
    case 475:
    case "CapitalizedFeeValue1":
      return EDealershipTemplateFieldType.CapitalizedFeeValue1;
    case 476:
    case "CapitalizedFeeName2":
      return EDealershipTemplateFieldType.CapitalizedFeeName2;
    case 477:
    case "CapitalizedFeeValue2":
      return EDealershipTemplateFieldType.CapitalizedFeeValue2;
    case 478:
    case "CapitalizedFeeName3":
      return EDealershipTemplateFieldType.CapitalizedFeeName3;
    case 479:
    case "CapitalizedFeeValue3":
      return EDealershipTemplateFieldType.CapitalizedFeeValue3;
    case 480:
    case "CapitalizedFeeName4":
      return EDealershipTemplateFieldType.CapitalizedFeeName4;
    case 481:
    case "CapitalizedFeeValue4":
      return EDealershipTemplateFieldType.CapitalizedFeeValue4;
    case 482:
    case "CapitalizedFeeName5":
      return EDealershipTemplateFieldType.CapitalizedFeeName5;
    case 483:
    case "CapitalizedFeeValue5":
      return EDealershipTemplateFieldType.CapitalizedFeeValue5;
    case 484:
    case "CapitalizedFeeName6":
      return EDealershipTemplateFieldType.CapitalizedFeeName6;
    case 485:
    case "CapitalizedFeeValue6":
      return EDealershipTemplateFieldType.CapitalizedFeeValue6;
    case 486:
    case "CapitalizedFeeName7":
      return EDealershipTemplateFieldType.CapitalizedFeeName7;
    case 487:
    case "CapitalizedFeeValue7":
      return EDealershipTemplateFieldType.CapitalizedFeeValue7;
    case 488:
    case "CapitalizedFeeName8":
      return EDealershipTemplateFieldType.CapitalizedFeeName8;
    case 489:
    case "CapitalizedFeeValue8":
      return EDealershipTemplateFieldType.CapitalizedFeeValue8;
    case 490:
    case "CapitalizedFeeName9":
      return EDealershipTemplateFieldType.CapitalizedFeeName9;
    case 491:
    case "CapitalizedFeeValue9":
      return EDealershipTemplateFieldType.CapitalizedFeeValue9;
    case 492:
    case "CapitalizedFeeName10":
      return EDealershipTemplateFieldType.CapitalizedFeeName10;
    case 493:
    case "CapitalizedFeeValue10":
      return EDealershipTemplateFieldType.CapitalizedFeeValue10;
    case 494:
    case "CapitalizedFeeName11":
      return EDealershipTemplateFieldType.CapitalizedFeeName11;
    case 495:
    case "CapitalizedFeeValue11":
      return EDealershipTemplateFieldType.CapitalizedFeeValue11;
    case 496:
    case "CapitalizedFeeName12":
      return EDealershipTemplateFieldType.CapitalizedFeeName12;
    case 497:
    case "CapitalizedFeeValue12":
      return EDealershipTemplateFieldType.CapitalizedFeeValue12;
    case 498:
    case "CapitalizedFeeName13":
      return EDealershipTemplateFieldType.CapitalizedFeeName13;
    case 499:
    case "CapitalizedFeeValue13":
      return EDealershipTemplateFieldType.CapitalizedFeeValue13;
    case 500:
    case "CapitalizedFeeName14":
      return EDealershipTemplateFieldType.CapitalizedFeeName14;
    case 501:
    case "CapitalizedFeeValue14":
      return EDealershipTemplateFieldType.CapitalizedFeeValue14;
    case 502:
    case "CapitalizedFeeName15":
      return EDealershipTemplateFieldType.CapitalizedFeeName15;
    case 503:
    case "CapitalizedFeeValue15":
      return EDealershipTemplateFieldType.CapitalizedFeeValue15;
    case 504:
    case "UpfrontFeeName1":
      return EDealershipTemplateFieldType.UpfrontFeeName1;
    case 505:
    case "UpfrontFeeValue1":
      return EDealershipTemplateFieldType.UpfrontFeeValue1;
    case 506:
    case "UpfrontFeeName2":
      return EDealershipTemplateFieldType.UpfrontFeeName2;
    case 507:
    case "UpfrontFeeValue2":
      return EDealershipTemplateFieldType.UpfrontFeeValue2;
    case 508:
    case "UpfrontFeeName3":
      return EDealershipTemplateFieldType.UpfrontFeeName3;
    case 509:
    case "UpfrontFeeValue3":
      return EDealershipTemplateFieldType.UpfrontFeeValue3;
    case 510:
    case "UpfrontFeeName4":
      return EDealershipTemplateFieldType.UpfrontFeeName4;
    case 511:
    case "UpfrontFeeValue4":
      return EDealershipTemplateFieldType.UpfrontFeeValue4;
    case 512:
    case "UpfrontFeeName5":
      return EDealershipTemplateFieldType.UpfrontFeeName5;
    case 513:
    case "UpfrontFeeValue5":
      return EDealershipTemplateFieldType.UpfrontFeeValue5;
    case 514:
    case "UpfrontFeeName6":
      return EDealershipTemplateFieldType.UpfrontFeeName6;
    case 515:
    case "UpfrontFeeValue6":
      return EDealershipTemplateFieldType.UpfrontFeeValue6;
    case 516:
    case "UpfrontFeeName7":
      return EDealershipTemplateFieldType.UpfrontFeeName7;
    case 517:
    case "UpfrontFeeValue7":
      return EDealershipTemplateFieldType.UpfrontFeeValue7;
    case 518:
    case "UpfrontFeeName8":
      return EDealershipTemplateFieldType.UpfrontFeeName8;
    case 519:
    case "UpfrontFeeValue8":
      return EDealershipTemplateFieldType.UpfrontFeeValue8;
    case 520:
    case "UpfrontFeeName9":
      return EDealershipTemplateFieldType.UpfrontFeeName9;
    case 521:
    case "UpfrontFeeValue9":
      return EDealershipTemplateFieldType.UpfrontFeeValue9;
    case 522:
    case "UpfrontFeeName10":
      return EDealershipTemplateFieldType.UpfrontFeeName10;
    case 523:
    case "UpfrontFeeValue10":
      return EDealershipTemplateFieldType.UpfrontFeeValue10;
    case 524:
    case "UpfrontFeeName11":
      return EDealershipTemplateFieldType.UpfrontFeeName11;
    case 525:
    case "UpfrontFeeValue11":
      return EDealershipTemplateFieldType.UpfrontFeeValue11;
    case 526:
    case "UpfrontFeeName12":
      return EDealershipTemplateFieldType.UpfrontFeeName12;
    case 527:
    case "UpfrontFeeValue12":
      return EDealershipTemplateFieldType.UpfrontFeeValue12;
    case 528:
    case "UpfrontFeeName13":
      return EDealershipTemplateFieldType.UpfrontFeeName13;
    case 529:
    case "UpfrontFeeValue13":
      return EDealershipTemplateFieldType.UpfrontFeeValue13;
    case 530:
    case "UpfrontFeeName14":
      return EDealershipTemplateFieldType.UpfrontFeeName14;
    case 531:
    case "UpfrontFeeValue14":
      return EDealershipTemplateFieldType.UpfrontFeeValue14;
    case 532:
    case "UpfrontFeeName15":
      return EDealershipTemplateFieldType.UpfrontFeeName15;
    case 533:
    case "UpfrontFeeValue15":
      return EDealershipTemplateFieldType.UpfrontFeeValue15;
    case 534:
    case "CapitalizedFees":
      return EDealershipTemplateFieldType.CapitalizedFees;
    case 535:
    case "StateTax":
      return EDealershipTemplateFieldType.StateTax;
    case 536:
    case "CountyTax":
      return EDealershipTemplateFieldType.CountyTax;
    case 537:
    case "CityTax":
      return EDealershipTemplateFieldType.CityTax;
    case 538:
    case "UpfrontFees":
      return EDealershipTemplateFieldType.UpfrontFees;
    case 539:
    case "MonthlyTaxPayment":
      return EDealershipTemplateFieldType.MonthlyTaxPayment;
    case 540:
    case "TitleRegistrationFee":
      return EDealershipTemplateFieldType.TitleRegistrationFee;
    case 541:
    case "TotalPriceWithOptions":
      return EDealershipTemplateFieldType.TotalPriceWithOptions;
    case 542:
    case "TotalSellingPrice":
      return EDealershipTemplateFieldType.TotalSellingPrice;
    case 543:
    case "CustomField":
      return EDealershipTemplateFieldType.CustomField;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EDealershipTemplateFieldType.UNRECOGNIZED;
  }
}

export function eDealershipTemplateFieldTypeToJSON(object: EDealershipTemplateFieldType): string {
  switch (object) {
    case EDealershipTemplateFieldType.Unspecified:
      return "Unspecified";
    case EDealershipTemplateFieldType.DealershipSignature:
      return "DealershipSignature";
    case EDealershipTemplateFieldType.DealershipName:
      return "DealershipName";
    case EDealershipTemplateFieldType.DealershipPhone:
      return "DealershipPhone";
    case EDealershipTemplateFieldType.DealershipEmail:
      return "DealershipEmail";
    case EDealershipTemplateFieldType.DealershipStreetAndNumber:
      return "DealershipStreetAndNumber";
    case EDealershipTemplateFieldType.DealershipCity:
      return "DealershipCity";
    case EDealershipTemplateFieldType.DealershipState:
      return "DealershipState";
    case EDealershipTemplateFieldType.DealershipZip:
      return "DealershipZip";
    case EDealershipTemplateFieldType.VehicleStockNumber:
      return "VehicleStockNumber";
    case EDealershipTemplateFieldType.VehicleYear:
      return "VehicleYear";
    case EDealershipTemplateFieldType.VehicleMake:
      return "VehicleMake";
    case EDealershipTemplateFieldType.VehicleModel:
      return "VehicleModel";
    case EDealershipTemplateFieldType.VehicleTrim:
      return "VehicleTrim";
    case EDealershipTemplateFieldType.VehicleVin:
      return "VehicleVin";
    case EDealershipTemplateFieldType.VehicleColor:
      return "VehicleColor";
    case EDealershipTemplateFieldType.VehicleMileage:
      return "VehicleMileage";
    case EDealershipTemplateFieldType.VehicleCashPrice:
      return "VehicleCashPrice";
    case EDealershipTemplateFieldType.CashDown:
      return "CashDown";
    case EDealershipTemplateFieldType.InsuranceCompanyName:
      return "InsuranceCompanyName";
    case EDealershipTemplateFieldType.InsuranceEffectiveDateFrom:
      return "InsuranceEffectiveDateFrom";
    case EDealershipTemplateFieldType.InsuranceEffectiveDateTo:
      return "InsuranceEffectiveDateTo";
    case EDealershipTemplateFieldType.InsurancePolicyNumber:
      return "InsurancePolicyNumber";
    case EDealershipTemplateFieldType.InsuranceAgentName:
      return "InsuranceAgentName";
    case EDealershipTemplateFieldType.InsuranceCompanyState:
      return "InsuranceCompanyState";
    case EDealershipTemplateFieldType.InsuranceCompanyZip:
      return "InsuranceCompanyZip";
    case EDealershipTemplateFieldType.InsuranceCompanyCity:
      return "InsuranceCompanyCity";
    case EDealershipTemplateFieldType.InsuranceStreetAndNumber:
      return "InsuranceStreetAndNumber";
    case EDealershipTemplateFieldType.SalesPersonName:
      return "SalesPersonName";
    case EDealershipTemplateFieldType.TradeVehicleTrim:
      return "TradeVehicleTrim";
    case EDealershipTemplateFieldType.TradeVehicleMake:
      return "TradeVehicleMake";
    case EDealershipTemplateFieldType.TradeVehicleModel:
      return "TradeVehicleModel";
    case EDealershipTemplateFieldType.TradeVehicleYear:
      return "TradeVehicleYear";
    case EDealershipTemplateFieldType.TradeVehicleVin:
      return "TradeVehicleVin";
    case EDealershipTemplateFieldType.TradeVehicleLicensePlate:
      return "TradeVehicleLicensePlate";
    case EDealershipTemplateFieldType.TradeVehicleMileage:
      return "TradeVehicleMileage";
    case EDealershipTemplateFieldType.LenderName:
      return "LenderName";
    case EDealershipTemplateFieldType.ServiceContract:
      return "ServiceContract";
    case EDealershipTemplateFieldType.VehicleBody:
      return "VehicleBody";
    case EDealershipTemplateFieldType.VehicleTransmission:
      return "VehicleTransmission";
    case EDealershipTemplateFieldType.DeliverDate:
      return "DeliverDate";
    case EDealershipTemplateFieldType.TotalPrice:
      return "TotalPrice";
    case EDealershipTemplateFieldType.PlateFee:
      return "PlateFee";
    case EDealershipTemplateFieldType.DocumentFee:
      return "DocumentFee";
    case EDealershipTemplateFieldType.VehicleGaragedAddress:
      return "VehicleGaragedAddress";
    case EDealershipTemplateFieldType.GapContractDescription:
      return "GapContractDescription";
    case EDealershipTemplateFieldType.GapContractPrice:
      return "GapContractPrice";
    case EDealershipTemplateFieldType.ProtectionProductsPrice:
      return "ProtectionProductsPrice";
    case EDealershipTemplateFieldType.DueAtSigning:
      return "DueAtSigning";
    case EDealershipTemplateFieldType.TradePayOff:
      return "TradePayOff";
    case EDealershipTemplateFieldType.DmsDealNumber:
      return "DmsDealNumber";
    case EDealershipTemplateFieldType.LienHolderName:
      return "LienHolderName";
    case EDealershipTemplateFieldType.LienHolderAddress:
      return "LienHolderAddress";
    case EDealershipTemplateFieldType.TaxName1:
      return "TaxName1";
    case EDealershipTemplateFieldType.TaxValue1:
      return "TaxValue1";
    case EDealershipTemplateFieldType.TaxName2:
      return "TaxName2";
    case EDealershipTemplateFieldType.TaxValue2:
      return "TaxValue2";
    case EDealershipTemplateFieldType.TaxName3:
      return "TaxName3";
    case EDealershipTemplateFieldType.TaxValue3:
      return "TaxValue3";
    case EDealershipTemplateFieldType.TaxName4:
      return "TaxName4";
    case EDealershipTemplateFieldType.TaxValue4:
      return "TaxValue4";
    case EDealershipTemplateFieldType.TaxName5:
      return "TaxName5";
    case EDealershipTemplateFieldType.TaxValue5:
      return "TaxValue5";
    case EDealershipTemplateFieldType.TaxName6:
      return "TaxName6";
    case EDealershipTemplateFieldType.TaxValue6:
      return "TaxValue6";
    case EDealershipTemplateFieldType.TaxName7:
      return "TaxName7";
    case EDealershipTemplateFieldType.TaxValue7:
      return "TaxValue7";
    case EDealershipTemplateFieldType.TaxName8:
      return "TaxName8";
    case EDealershipTemplateFieldType.TaxValue8:
      return "TaxValue8";
    case EDealershipTemplateFieldType.TaxName9:
      return "TaxName9";
    case EDealershipTemplateFieldType.TaxValue9:
      return "TaxValue9";
    case EDealershipTemplateFieldType.TaxName10:
      return "TaxName10";
    case EDealershipTemplateFieldType.TaxValue10:
      return "TaxValue10";
    case EDealershipTemplateFieldType.TaxName11:
      return "TaxName11";
    case EDealershipTemplateFieldType.TaxValue11:
      return "TaxValue11";
    case EDealershipTemplateFieldType.TaxName12:
      return "TaxName12";
    case EDealershipTemplateFieldType.TaxValue12:
      return "TaxValue12";
    case EDealershipTemplateFieldType.TaxName13:
      return "TaxName13";
    case EDealershipTemplateFieldType.TaxValue13:
      return "TaxValue13";
    case EDealershipTemplateFieldType.TaxName14:
      return "TaxName14";
    case EDealershipTemplateFieldType.TaxValue14:
      return "TaxValue14";
    case EDealershipTemplateFieldType.TaxName15:
      return "TaxName15";
    case EDealershipTemplateFieldType.TaxValue15:
      return "TaxValue15";
    case EDealershipTemplateFieldType.AccessoryName1:
      return "AccessoryName1";
    case EDealershipTemplateFieldType.AccessoryVendor1:
      return "AccessoryVendor1";
    case EDealershipTemplateFieldType.AccessoryPrice1:
      return "AccessoryPrice1";
    case EDealershipTemplateFieldType.AccessoryName2:
      return "AccessoryName2";
    case EDealershipTemplateFieldType.AccessoryVendor2:
      return "AccessoryVendor2";
    case EDealershipTemplateFieldType.AccessoryPrice2:
      return "AccessoryPrice2";
    case EDealershipTemplateFieldType.AccessoryName3:
      return "AccessoryName3";
    case EDealershipTemplateFieldType.AccessoryVendor3:
      return "AccessoryVendor3";
    case EDealershipTemplateFieldType.AccessoryPrice3:
      return "AccessoryPrice3";
    case EDealershipTemplateFieldType.AccessoryName4:
      return "AccessoryName4";
    case EDealershipTemplateFieldType.AccessoryVendor4:
      return "AccessoryVendor4";
    case EDealershipTemplateFieldType.AccessoryPrice4:
      return "AccessoryPrice4";
    case EDealershipTemplateFieldType.AccessoryName5:
      return "AccessoryName5";
    case EDealershipTemplateFieldType.AccessoryVendor5:
      return "AccessoryVendor5";
    case EDealershipTemplateFieldType.AccessoryPrice5:
      return "AccessoryPrice5";
    case EDealershipTemplateFieldType.AccessoryName6:
      return "AccessoryName6";
    case EDealershipTemplateFieldType.AccessoryVendor6:
      return "AccessoryVendor6";
    case EDealershipTemplateFieldType.AccessoryPrice6:
      return "AccessoryPrice6";
    case EDealershipTemplateFieldType.AccessoryName7:
      return "AccessoryName7";
    case EDealershipTemplateFieldType.AccessoryVendor7:
      return "AccessoryVendor7";
    case EDealershipTemplateFieldType.AccessoryPrice7:
      return "AccessoryPrice7";
    case EDealershipTemplateFieldType.AccessoryName8:
      return "AccessoryName8";
    case EDealershipTemplateFieldType.AccessoryVendor8:
      return "AccessoryVendor8";
    case EDealershipTemplateFieldType.AccessoryPrice8:
      return "AccessoryPrice8";
    case EDealershipTemplateFieldType.AccessoryName9:
      return "AccessoryName9";
    case EDealershipTemplateFieldType.AccessoryVendor9:
      return "AccessoryVendor9";
    case EDealershipTemplateFieldType.AccessoryPrice9:
      return "AccessoryPrice9";
    case EDealershipTemplateFieldType.AccessoryName10:
      return "AccessoryName10";
    case EDealershipTemplateFieldType.AccessoryVendor10:
      return "AccessoryVendor10";
    case EDealershipTemplateFieldType.AccessoryPrice10:
      return "AccessoryPrice10";
    case EDealershipTemplateFieldType.AccessoryName11:
      return "AccessoryName11";
    case EDealershipTemplateFieldType.AccessoryVendor11:
      return "AccessoryVendor11";
    case EDealershipTemplateFieldType.AccessoryPrice11:
      return "AccessoryPrice11";
    case EDealershipTemplateFieldType.AccessoryName12:
      return "AccessoryName12";
    case EDealershipTemplateFieldType.AccessoryVendor12:
      return "AccessoryVendor12";
    case EDealershipTemplateFieldType.AccessoryPrice12:
      return "AccessoryPrice12";
    case EDealershipTemplateFieldType.AccessoryName13:
      return "AccessoryName13";
    case EDealershipTemplateFieldType.AccessoryVendor13:
      return "AccessoryVendor13";
    case EDealershipTemplateFieldType.AccessoryPrice13:
      return "AccessoryPrice13";
    case EDealershipTemplateFieldType.AccessoryName14:
      return "AccessoryName14";
    case EDealershipTemplateFieldType.AccessoryVendor14:
      return "AccessoryVendor14";
    case EDealershipTemplateFieldType.AccessoryPrice14:
      return "AccessoryPrice14";
    case EDealershipTemplateFieldType.AccessoryName15:
      return "AccessoryName15";
    case EDealershipTemplateFieldType.AccessoryVendor15:
      return "AccessoryVendor15";
    case EDealershipTemplateFieldType.AccessoryPrice15:
      return "AccessoryPrice15";
    case EDealershipTemplateFieldType.ServiceContractDescription:
      return "ServiceContractDescription";
    case EDealershipTemplateFieldType.ServiceContractPrice:
      return "ServiceContractPrice";
    case EDealershipTemplateFieldType.BasePayment:
      return "BasePayment";
    case EDealershipTemplateFieldType.AccessoriesTotalPrice:
      return "AccessoriesTotalPrice";
    case EDealershipTemplateFieldType.InsuranceCompanyPhoneNumber:
      return "InsuranceCompanyPhoneNumber";
    case EDealershipTemplateFieldType.ProtectionProductName1:
      return "ProtectionProductName1";
    case EDealershipTemplateFieldType.ProtectionProductPrice1:
      return "ProtectionProductPrice1";
    case EDealershipTemplateFieldType.ProtectionProductDescription1:
      return "ProtectionProductDescription1";
    case EDealershipTemplateFieldType.ProtectionProductName2:
      return "ProtectionProductName2";
    case EDealershipTemplateFieldType.ProtectionProductPrice2:
      return "ProtectionProductPrice2";
    case EDealershipTemplateFieldType.ProtectionProductDescription2:
      return "ProtectionProductDescription2";
    case EDealershipTemplateFieldType.ProtectionProductName3:
      return "ProtectionProductName3";
    case EDealershipTemplateFieldType.ProtectionProductPrice3:
      return "ProtectionProductPrice3";
    case EDealershipTemplateFieldType.ProtectionProductDescription3:
      return "ProtectionProductDescription3";
    case EDealershipTemplateFieldType.ProtectionProductName4:
      return "ProtectionProductName4";
    case EDealershipTemplateFieldType.ProtectionProductPrice4:
      return "ProtectionProductPrice4";
    case EDealershipTemplateFieldType.ProtectionProductDescription4:
      return "ProtectionProductDescription4";
    case EDealershipTemplateFieldType.ProtectionProductName5:
      return "ProtectionProductName5";
    case EDealershipTemplateFieldType.ProtectionProductPrice5:
      return "ProtectionProductPrice5";
    case EDealershipTemplateFieldType.ProtectionProductDescription5:
      return "ProtectionProductDescription5";
    case EDealershipTemplateFieldType.ProtectionProductName6:
      return "ProtectionProductName6";
    case EDealershipTemplateFieldType.ProtectionProductPrice6:
      return "ProtectionProductPrice6";
    case EDealershipTemplateFieldType.ProtectionProductDescription6:
      return "ProtectionProductDescription6";
    case EDealershipTemplateFieldType.ProtectionProductName7:
      return "ProtectionProductName7";
    case EDealershipTemplateFieldType.ProtectionProductPrice7:
      return "ProtectionProductPrice7";
    case EDealershipTemplateFieldType.ProtectionProductDescription7:
      return "ProtectionProductDescription7";
    case EDealershipTemplateFieldType.ProtectionProductName8:
      return "ProtectionProductName8";
    case EDealershipTemplateFieldType.ProtectionProductPrice8:
      return "ProtectionProductPrice8";
    case EDealershipTemplateFieldType.ProtectionProductDescription8:
      return "ProtectionProductDescription8";
    case EDealershipTemplateFieldType.ProtectionProductName9:
      return "ProtectionProductName9";
    case EDealershipTemplateFieldType.ProtectionProductPrice9:
      return "ProtectionProductPrice9";
    case EDealershipTemplateFieldType.ProtectionProductDescription9:
      return "ProtectionProductDescription9";
    case EDealershipTemplateFieldType.ProtectionProductName10:
      return "ProtectionProductName10";
    case EDealershipTemplateFieldType.ProtectionProductPrice10:
      return "ProtectionProductPrice10";
    case EDealershipTemplateFieldType.ProtectionProductDescription10:
      return "ProtectionProductDescription10";
    case EDealershipTemplateFieldType.Common_Shared_FreeText:
      return "Common_Shared_FreeText";
    case EDealershipTemplateFieldType.Common_Shared_CheckBox:
      return "Common_Shared_CheckBox";
    case EDealershipTemplateFieldType.AllProtectionProductsPrice:
      return "AllProtectionProductsPrice";
    case EDealershipTemplateFieldType.TotalFees:
      return "TotalFees";
    case EDealershipTemplateFieldType.TotalRebates:
      return "TotalRebates";
    case EDealershipTemplateFieldType.SecondTradeVehicleTrim:
      return "SecondTradeVehicleTrim";
    case EDealershipTemplateFieldType.SecondTradeVehicleMake:
      return "SecondTradeVehicleMake";
    case EDealershipTemplateFieldType.SecondTradeVehicleModel:
      return "SecondTradeVehicleModel";
    case EDealershipTemplateFieldType.SecondTradeVehicleYear:
      return "SecondTradeVehicleYear";
    case EDealershipTemplateFieldType.SecondTradeVehicleVin:
      return "SecondTradeVehicleVin";
    case EDealershipTemplateFieldType.SecondTradeVehicleMileage:
      return "SecondTradeVehicleMileage";
    case EDealershipTemplateFieldType.LienHolderCity:
      return "LienHolderCity";
    case EDealershipTemplateFieldType.LienHolderState:
      return "LienHolderState";
    case EDealershipTemplateFieldType.LienHolderZip:
      return "LienHolderZip";
    case EDealershipTemplateFieldType.LienHolderStreet:
      return "LienHolderStreet";
    case EDealershipTemplateFieldType.TradeLienHolderName:
      return "TradeLienHolderName";
    case EDealershipTemplateFieldType.TradeLienHolderCity:
      return "TradeLienHolderCity";
    case EDealershipTemplateFieldType.TradeLienHolderState:
      return "TradeLienHolderState";
    case EDealershipTemplateFieldType.TradeLienHolderZip:
      return "TradeLienHolderZip";
    case EDealershipTemplateFieldType.TradeLienHolderStreet:
      return "TradeLienHolderStreet";
    case EDealershipTemplateFieldType.PolicyHolderName:
      return "PolicyHolderName";
    case EDealershipTemplateFieldType.PolicyHolderCity:
      return "PolicyHolderCity";
    case EDealershipTemplateFieldType.PolicyHolderState:
      return "PolicyHolderState";
    case EDealershipTemplateFieldType.PolicyHolderZip:
      return "PolicyHolderZip";
    case EDealershipTemplateFieldType.PolicyHolderStreet:
      return "PolicyHolderStreet";
    case EDealershipTemplateFieldType.Payment:
      return "Payment";
    case EDealershipTemplateFieldType.TaxRate:
      return "TaxRate";
    case EDealershipTemplateFieldType.TotalFinanceAmount:
      return "TotalFinanceAmount";
    case EDealershipTemplateFieldType.DealType:
      return "DealType";
    case EDealershipTemplateFieldType.TradeValue:
      return "TradeValue";
    case EDealershipTemplateFieldType.SecondTradeValue:
      return "SecondTradeValue";
    case EDealershipTemplateFieldType.TradeNetValue:
      return "TradeNetValue";
    case EDealershipTemplateFieldType.SecondTradeNetValue:
      return "SecondTradeNetValue";
    case EDealershipTemplateFieldType.TotalTaxes:
      return "TotalTaxes";
    case EDealershipTemplateFieldType.TaxableFieldName1:
      return "TaxableFieldName1";
    case EDealershipTemplateFieldType.TaxableFieldValue1:
      return "TaxableFieldValue1";
    case EDealershipTemplateFieldType.TaxableFieldName2:
      return "TaxableFieldName2";
    case EDealershipTemplateFieldType.TaxableFieldValue2:
      return "TaxableFieldValue2";
    case EDealershipTemplateFieldType.TaxableFieldName3:
      return "TaxableFieldName3";
    case EDealershipTemplateFieldType.TaxableFieldValue3:
      return "TaxableFieldValue3";
    case EDealershipTemplateFieldType.TaxableFieldName4:
      return "TaxableFieldName4";
    case EDealershipTemplateFieldType.TaxableFieldValue4:
      return "TaxableFieldValue4";
    case EDealershipTemplateFieldType.TaxableFieldName5:
      return "TaxableFieldName5";
    case EDealershipTemplateFieldType.TaxableFieldValue5:
      return "TaxableFieldValue5";
    case EDealershipTemplateFieldType.TaxableFieldName6:
      return "TaxableFieldName6";
    case EDealershipTemplateFieldType.TaxableFieldValue6:
      return "TaxableFieldValue6";
    case EDealershipTemplateFieldType.TaxableFieldName7:
      return "TaxableFieldName7";
    case EDealershipTemplateFieldType.TaxableFieldValue7:
      return "TaxableFieldValue7";
    case EDealershipTemplateFieldType.TaxableFieldName8:
      return "TaxableFieldName8";
    case EDealershipTemplateFieldType.TaxableFieldValue8:
      return "TaxableFieldValue8";
    case EDealershipTemplateFieldType.TaxableFieldName9:
      return "TaxableFieldName9";
    case EDealershipTemplateFieldType.TaxableFieldValue9:
      return "TaxableFieldValue9";
    case EDealershipTemplateFieldType.TaxableFieldName10:
      return "TaxableFieldName10";
    case EDealershipTemplateFieldType.TaxableFieldValue10:
      return "TaxableFieldValue10";
    case EDealershipTemplateFieldType.TaxableFieldName11:
      return "TaxableFieldName11";
    case EDealershipTemplateFieldType.TaxableFieldValue11:
      return "TaxableFieldValue11";
    case EDealershipTemplateFieldType.TaxableFieldName12:
      return "TaxableFieldName12";
    case EDealershipTemplateFieldType.TaxableFieldValue12:
      return "TaxableFieldValue12";
    case EDealershipTemplateFieldType.TaxableFieldName13:
      return "TaxableFieldName13";
    case EDealershipTemplateFieldType.TaxableFieldValue13:
      return "TaxableFieldValue13";
    case EDealershipTemplateFieldType.TaxableFieldName14:
      return "TaxableFieldName14";
    case EDealershipTemplateFieldType.TaxableFieldValue14:
      return "TaxableFieldValue14";
    case EDealershipTemplateFieldType.TaxableFieldName15:
      return "TaxableFieldName15";
    case EDealershipTemplateFieldType.TaxableFieldValue15:
      return "TaxableFieldValue15";
    case EDealershipTemplateFieldType.NonTaxableFeeName1:
      return "NonTaxableFeeName1";
    case EDealershipTemplateFieldType.NonTaxableFeeValue1:
      return "NonTaxableFeeValue1";
    case EDealershipTemplateFieldType.NonTaxableFeeName2:
      return "NonTaxableFeeName2";
    case EDealershipTemplateFieldType.NonTaxableFeeValue2:
      return "NonTaxableFeeValue2";
    case EDealershipTemplateFieldType.NonTaxableFeeName3:
      return "NonTaxableFeeName3";
    case EDealershipTemplateFieldType.NonTaxableFeeValue3:
      return "NonTaxableFeeValue3";
    case EDealershipTemplateFieldType.NonTaxableFeeName4:
      return "NonTaxableFeeName4";
    case EDealershipTemplateFieldType.NonTaxableFeeValue4:
      return "NonTaxableFeeValue4";
    case EDealershipTemplateFieldType.NonTaxableFeeName5:
      return "NonTaxableFeeName5";
    case EDealershipTemplateFieldType.NonTaxableFeeValue5:
      return "NonTaxableFeeValue5";
    case EDealershipTemplateFieldType.NonTaxableFeeName6:
      return "NonTaxableFeeName6";
    case EDealershipTemplateFieldType.NonTaxableFeeValue6:
      return "NonTaxableFeeValue6";
    case EDealershipTemplateFieldType.NonTaxableFeeName7:
      return "NonTaxableFeeName7";
    case EDealershipTemplateFieldType.NonTaxableFeeValue7:
      return "NonTaxableFeeValue7";
    case EDealershipTemplateFieldType.NonTaxableFeeName8:
      return "NonTaxableFeeName8";
    case EDealershipTemplateFieldType.NonTaxableFeeValue8:
      return "NonTaxableFeeValue8";
    case EDealershipTemplateFieldType.NonTaxableFeeName9:
      return "NonTaxableFeeName9";
    case EDealershipTemplateFieldType.NonTaxableFeeValue9:
      return "NonTaxableFeeValue9";
    case EDealershipTemplateFieldType.NonTaxableFeeName10:
      return "NonTaxableFeeName10";
    case EDealershipTemplateFieldType.NonTaxableFeeValue10:
      return "NonTaxableFeeValue10";
    case EDealershipTemplateFieldType.NonTaxableFeeName11:
      return "NonTaxableFeeName11";
    case EDealershipTemplateFieldType.NonTaxableFeeValue11:
      return "NonTaxableFeeValue11";
    case EDealershipTemplateFieldType.NonTaxableFeeName12:
      return "NonTaxableFeeName12";
    case EDealershipTemplateFieldType.NonTaxableFeeValue12:
      return "NonTaxableFeeValue12";
    case EDealershipTemplateFieldType.NonTaxableFeeName13:
      return "NonTaxableFeeName13";
    case EDealershipTemplateFieldType.NonTaxableFeeValue13:
      return "NonTaxableFeeValue13";
    case EDealershipTemplateFieldType.NonTaxableFeeName14:
      return "NonTaxableFeeName14";
    case EDealershipTemplateFieldType.NonTaxableFeeValue14:
      return "NonTaxableFeeValue14";
    case EDealershipTemplateFieldType.NonTaxableFeeName15:
      return "NonTaxableFeeName15";
    case EDealershipTemplateFieldType.NonTaxableFeeValue15:
      return "NonTaxableFeeValue15";
    case EDealershipTemplateFieldType.CancelableProductName1:
      return "CancelableProductName1";
    case EDealershipTemplateFieldType.CancelableProductPrice1:
      return "CancelableProductPrice1";
    case EDealershipTemplateFieldType.CancelableProductName2:
      return "CancelableProductName2";
    case EDealershipTemplateFieldType.CancelableProductPrice2:
      return "CancelableProductPrice2";
    case EDealershipTemplateFieldType.CancelableProductName3:
      return "CancelableProductName3";
    case EDealershipTemplateFieldType.CancelableProductPrice3:
      return "CancelableProductPrice3";
    case EDealershipTemplateFieldType.CancelableProductName4:
      return "CancelableProductName4";
    case EDealershipTemplateFieldType.CancelableProductPrice4:
      return "CancelableProductPrice4";
    case EDealershipTemplateFieldType.CancelableProductName5:
      return "CancelableProductName5";
    case EDealershipTemplateFieldType.CancelableProductPrice5:
      return "CancelableProductPrice5";
    case EDealershipTemplateFieldType.CancelableProductName6:
      return "CancelableProductName6";
    case EDealershipTemplateFieldType.CancelableProductPrice6:
      return "CancelableProductPrice6";
    case EDealershipTemplateFieldType.CancelableProductName7:
      return "CancelableProductName7";
    case EDealershipTemplateFieldType.CancelableProductPrice7:
      return "CancelableProductPrice7";
    case EDealershipTemplateFieldType.CancelableProductName8:
      return "CancelableProductName8";
    case EDealershipTemplateFieldType.CancelableProductPrice8:
      return "CancelableProductPrice8";
    case EDealershipTemplateFieldType.CancelableProductName9:
      return "CancelableProductName9";
    case EDealershipTemplateFieldType.CancelableProductPrice9:
      return "CancelableProductPrice9";
    case EDealershipTemplateFieldType.CancelableProductName10:
      return "CancelableProductName10";
    case EDealershipTemplateFieldType.CancelableProductPrice10:
      return "CancelableProductPrice10";
    case EDealershipTemplateFieldType.CancelableProductName11:
      return "CancelableProductName11";
    case EDealershipTemplateFieldType.CancelableProductPrice11:
      return "CancelableProductPrice11";
    case EDealershipTemplateFieldType.CancelableProductName12:
      return "CancelableProductName12";
    case EDealershipTemplateFieldType.CancelableProductPrice12:
      return "CancelableProductPrice12";
    case EDealershipTemplateFieldType.CancelableProductName13:
      return "CancelableProductName13";
    case EDealershipTemplateFieldType.CancelableProductPrice13:
      return "CancelableProductPrice13";
    case EDealershipTemplateFieldType.CancelableProductName14:
      return "CancelableProductName14";
    case EDealershipTemplateFieldType.CancelableProductPrice14:
      return "CancelableProductPrice14";
    case EDealershipTemplateFieldType.CancelableProductName15:
      return "CancelableProductName15";
    case EDealershipTemplateFieldType.CancelableProductPrice15:
      return "CancelableProductPrice15";
    case EDealershipTemplateFieldType.TaxableSubTotal:
      return "TaxableSubTotal";
    case EDealershipTemplateFieldType.Subtotal:
      return "Subtotal";
    case EDealershipTemplateFieldType.TotalFeesWithoutDoc:
      return "TotalFeesWithoutDoc";
    case EDealershipTemplateFieldType.TotalTradesValue:
      return "TotalTradesValue";
    case EDealershipTemplateFieldType.DoingBusinessAs:
      return "DoingBusinessAs";
    case EDealershipTemplateFieldType.EmployerIdentificationNumber:
      return "EmployerIdentificationNumber";
    case EDealershipTemplateFieldType.TradeDifference:
      return "TradeDifference";
    case EDealershipTemplateFieldType.ProductName:
      return "ProductName";
    case EDealershipTemplateFieldType.ProductPrice:
      return "ProductPrice";
    case EDealershipTemplateFieldType.ProductMileage:
      return "ProductMileage";
    case EDealershipTemplateFieldType.ProductTerm:
      return "ProductTerm";
    case EDealershipTemplateFieldType.Customer_Shared_CreditScore:
      return "Customer_Shared_CreditScore";
    case EDealershipTemplateFieldType.Customer_Shared_FirstName:
      return "Customer_Shared_FirstName";
    case EDealershipTemplateFieldType.Customer_Shared_LastName:
      return "Customer_Shared_LastName";
    case EDealershipTemplateFieldType.Customer_Shared_MiddleName:
      return "Customer_Shared_MiddleName";
    case EDealershipTemplateFieldType.Customer_Shared_DateOfBirth:
      return "Customer_Shared_DateOfBirth";
    case EDealershipTemplateFieldType.Customer_Shared_Initials:
      return "Customer_Shared_Initials";
    case EDealershipTemplateFieldType.Customer_Shared_PhoneNumber:
      return "Customer_Shared_PhoneNumber";
    case EDealershipTemplateFieldType.Customer_Shared_SecondaryPhone:
      return "Customer_Shared_SecondaryPhone";
    case EDealershipTemplateFieldType.Customer_Shared_Email:
      return "Customer_Shared_Email";
    case EDealershipTemplateFieldType.Customer_Shared_Ssn:
      return "Customer_Shared_Ssn";
    case EDealershipTemplateFieldType.Customer_Shared_StreetAndNumber:
      return "Customer_Shared_StreetAndNumber";
    case EDealershipTemplateFieldType.Customer_Shared_City:
      return "Customer_Shared_City";
    case EDealershipTemplateFieldType.Customer_Shared_State:
      return "Customer_Shared_State";
    case EDealershipTemplateFieldType.Customer_Shared_Zip:
      return "Customer_Shared_Zip";
    case EDealershipTemplateFieldType.Customer_Shared_County:
      return "Customer_Shared_County";
    case EDealershipTemplateFieldType.Customer_Shared_DriverLicenseNumber:
      return "Customer_Shared_DriverLicenseNumber";
    case EDealershipTemplateFieldType.Customer_Shared_PreviousStreetAndNumber:
      return "Customer_Shared_PreviousStreetAndNumber";
    case EDealershipTemplateFieldType.Customer_Shared_PreviousCity:
      return "Customer_Shared_PreviousCity";
    case EDealershipTemplateFieldType.Customer_Shared_PreviousState:
      return "Customer_Shared_PreviousState";
    case EDealershipTemplateFieldType.Customer_Shared_PreviousZip:
      return "Customer_Shared_PreviousZip";
    case EDealershipTemplateFieldType.Customer_Shared_PreviousCounty:
      return "Customer_Shared_PreviousCounty";
    case EDealershipTemplateFieldType.Customer_Shared_TimeAtPreviousResidence:
      return "Customer_Shared_TimeAtPreviousResidence";
    case EDealershipTemplateFieldType.Customer_Shared_ResidenceType:
      return "Customer_Shared_ResidenceType";
    case EDealershipTemplateFieldType.Customer_Shared_AddressLine2:
      return "Customer_Shared_AddressLine2";
    case EDealershipTemplateFieldType.Customer_Shared_RentMortgagePayment:
      return "Customer_Shared_RentMortgagePayment";
    case EDealershipTemplateFieldType.Customer_Shared_TimeAtResidence:
      return "Customer_Shared_TimeAtResidence";
    case EDealershipTemplateFieldType.Customer_Shared_JobTitle:
      return "Customer_Shared_JobTitle";
    case EDealershipTemplateFieldType.Customer_Shared_Employer:
      return "Customer_Shared_Employer";
    case EDealershipTemplateFieldType.Customer_Shared_Income:
      return "Customer_Shared_Income";
    case EDealershipTemplateFieldType.Customer_Shared_OtherIncome:
      return "Customer_Shared_OtherIncome";
    case EDealershipTemplateFieldType.Customer_Shared_PreviousEmployee:
      return "Customer_Shared_PreviousEmployee";
    case EDealershipTemplateFieldType.Customer_Shared_EmployerPhone:
      return "Customer_Shared_EmployerPhone";
    case EDealershipTemplateFieldType.Customer_Shared_EmploymentStatus:
      return "Customer_Shared_EmploymentStatus";
    case EDealershipTemplateFieldType.Customer_Shared_TimeAtCurrentJob:
      return "Customer_Shared_TimeAtCurrentJob";
    case EDealershipTemplateFieldType.Customer_Shared_YearsOfEmployment:
      return "Customer_Shared_YearsOfEmployment";
    case EDealershipTemplateFieldType.Customer_Shared_MonthsOfEmployment:
      return "Customer_Shared_MonthsOfEmployment";
    case EDealershipTemplateFieldType.Customer_Shared_Date:
      return "Customer_Shared_Date";
    case EDealershipTemplateFieldType.Customer_Shared_Signature:
      return "Customer_Shared_Signature";
    case EDealershipTemplateFieldType.Date:
      return "Date";
    case EDealershipTemplateFieldType.TradeAccountNumber:
      return "TradeAccountNumber";
    case EDealershipTemplateFieldType.TradePayoffGoodUntilDate:
      return "TradePayoffGoodUntilDate";
    case EDealershipTemplateFieldType.TradePerDiem:
      return "TradePerDiem";
    case EDealershipTemplateFieldType.Customer_Shared_FullName:
      return "Customer_Shared_FullName";
    case EDealershipTemplateFieldType.Customer_Shared_FullAddress:
      return "Customer_Shared_FullAddress";
    case EDealershipTemplateFieldType.NonCancelableProductName1:
      return "NonCancelableProductName1";
    case EDealershipTemplateFieldType.NonCancelableProductPrice1:
      return "NonCancelableProductPrice1";
    case EDealershipTemplateFieldType.NonCancelableProductName2:
      return "NonCancelableProductName2";
    case EDealershipTemplateFieldType.NonCancelableProductPrice2:
      return "NonCancelableProductPrice2";
    case EDealershipTemplateFieldType.NonCancelableProductName3:
      return "NonCancelableProductName3";
    case EDealershipTemplateFieldType.NonCancelableProductPrice3:
      return "NonCancelableProductPrice3";
    case EDealershipTemplateFieldType.NonCancelableProductName4:
      return "NonCancelableProductName4";
    case EDealershipTemplateFieldType.NonCancelableProductPrice4:
      return "NonCancelableProductPrice4";
    case EDealershipTemplateFieldType.NonCancelableProductName5:
      return "NonCancelableProductName5";
    case EDealershipTemplateFieldType.NonCancelableProductPrice5:
      return "NonCancelableProductPrice5";
    case EDealershipTemplateFieldType.NonCancelableProductName6:
      return "NonCancelableProductName6";
    case EDealershipTemplateFieldType.NonCancelableProductPrice6:
      return "NonCancelableProductPrice6";
    case EDealershipTemplateFieldType.NonCancelableProductName7:
      return "NonCancelableProductName7";
    case EDealershipTemplateFieldType.NonCancelableProductPrice7:
      return "NonCancelableProductPrice7";
    case EDealershipTemplateFieldType.NonCancelableProductName8:
      return "NonCancelableProductName8";
    case EDealershipTemplateFieldType.NonCancelableProductPrice8:
      return "NonCancelableProductPrice8";
    case EDealershipTemplateFieldType.NonCancelableProductName9:
      return "NonCancelableProductName9";
    case EDealershipTemplateFieldType.NonCancelableProductPrice9:
      return "NonCancelableProductPrice9";
    case EDealershipTemplateFieldType.NonCancelableProductName10:
      return "NonCancelableProductName10";
    case EDealershipTemplateFieldType.NonCancelableProductPrice10:
      return "NonCancelableProductPrice10";
    case EDealershipTemplateFieldType.NonCancelableProductName11:
      return "NonCancelableProductName11";
    case EDealershipTemplateFieldType.NonCancelableProductPrice11:
      return "NonCancelableProductPrice11";
    case EDealershipTemplateFieldType.NonCancelableProductName12:
      return "NonCancelableProductName12";
    case EDealershipTemplateFieldType.NonCancelableProductPrice12:
      return "NonCancelableProductPrice12";
    case EDealershipTemplateFieldType.NonCancelableProductName13:
      return "NonCancelableProductName13";
    case EDealershipTemplateFieldType.NonCancelableProductPrice13:
      return "NonCancelableProductPrice13";
    case EDealershipTemplateFieldType.NonCancelableProductName14:
      return "NonCancelableProductName14";
    case EDealershipTemplateFieldType.NonCancelableProductPrice14:
      return "NonCancelableProductPrice14";
    case EDealershipTemplateFieldType.NonCancelableProductName15:
      return "NonCancelableProductName15";
    case EDealershipTemplateFieldType.NonCancelableProductPrice15:
      return "NonCancelableProductPrice15";
    case EDealershipTemplateFieldType.TotalFrontEndPrice:
      return "TotalFrontEndPrice";
    case EDealershipTemplateFieldType.VehicleTaxableAmount:
      return "VehicleTaxableAmount";
    case EDealershipTemplateFieldType.VehicleTotalAmount:
      return "VehicleTotalAmount";
    case EDealershipTemplateFieldType.VehicleTotalAmountWithoutPayoff:
      return "VehicleTotalAmountWithoutPayoff";
    case EDealershipTemplateFieldType.SellingPrice:
      return "SellingPrice";
    case EDealershipTemplateFieldType.SalesPersonEmail:
      return "SalesPersonEmail";
    case EDealershipTemplateFieldType.APR:
      return "APR";
    case EDealershipTemplateFieldType.DeclinedProductName1:
      return "DeclinedProductName1";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice1:
      return "DeclinedProductMonthlyPrice1";
    case EDealershipTemplateFieldType.DeclinedProductPrice1:
      return "DeclinedProductPrice1";
    case EDealershipTemplateFieldType.DeclinedProductTerm1:
      return "DeclinedProductTerm1";
    case EDealershipTemplateFieldType.DeclinedProductMileage1:
      return "DeclinedProductMileage1";
    case EDealershipTemplateFieldType.DeclinedProductName2:
      return "DeclinedProductName2";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice2:
      return "DeclinedProductMonthlyPrice2";
    case EDealershipTemplateFieldType.DeclinedProductPrice2:
      return "DeclinedProductPrice2";
    case EDealershipTemplateFieldType.DeclinedProductTerm2:
      return "DeclinedProductTerm2";
    case EDealershipTemplateFieldType.DeclinedProductMileage2:
      return "DeclinedProductMileage2";
    case EDealershipTemplateFieldType.DeclinedProductName3:
      return "DeclinedProductName3";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice3:
      return "DeclinedProductMonthlyPrice3";
    case EDealershipTemplateFieldType.DeclinedProductPrice3:
      return "DeclinedProductPrice3";
    case EDealershipTemplateFieldType.DeclinedProductTerm3:
      return "DeclinedProductTerm3";
    case EDealershipTemplateFieldType.DeclinedProductMileage3:
      return "DeclinedProductMileage3";
    case EDealershipTemplateFieldType.DeclinedProductName4:
      return "DeclinedProductName4";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice4:
      return "DeclinedProductMonthlyPrice4";
    case EDealershipTemplateFieldType.DeclinedProductPrice4:
      return "DeclinedProductPrice4";
    case EDealershipTemplateFieldType.DeclinedProductTerm4:
      return "DeclinedProductTerm4";
    case EDealershipTemplateFieldType.DeclinedProductMileage4:
      return "DeclinedProductMileage4";
    case EDealershipTemplateFieldType.DeclinedProductName5:
      return "DeclinedProductName5";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice5:
      return "DeclinedProductMonthlyPrice5";
    case EDealershipTemplateFieldType.DeclinedProductPrice5:
      return "DeclinedProductPrice5";
    case EDealershipTemplateFieldType.DeclinedProductTerm5:
      return "DeclinedProductTerm5";
    case EDealershipTemplateFieldType.DeclinedProductMileage5:
      return "DeclinedProductMileage5";
    case EDealershipTemplateFieldType.DeclinedProductName6:
      return "DeclinedProductName6";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice6:
      return "DeclinedProductMonthlyPrice6";
    case EDealershipTemplateFieldType.DeclinedProductPrice6:
      return "DeclinedProductPrice6";
    case EDealershipTemplateFieldType.DeclinedProductTerm6:
      return "DeclinedProductTerm6";
    case EDealershipTemplateFieldType.DeclinedProductMileage6:
      return "DeclinedProductMileage6";
    case EDealershipTemplateFieldType.DeclinedProductName7:
      return "DeclinedProductName7";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice7:
      return "DeclinedProductMonthlyPrice7";
    case EDealershipTemplateFieldType.DeclinedProductPrice7:
      return "DeclinedProductPrice7";
    case EDealershipTemplateFieldType.DeclinedProductTerm7:
      return "DeclinedProductTerm7";
    case EDealershipTemplateFieldType.DeclinedProductMileage7:
      return "DeclinedProductMileage7";
    case EDealershipTemplateFieldType.DeclinedProductName8:
      return "DeclinedProductName8";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice8:
      return "DeclinedProductMonthlyPrice8";
    case EDealershipTemplateFieldType.DeclinedProductPrice8:
      return "DeclinedProductPrice8";
    case EDealershipTemplateFieldType.DeclinedProductTerm8:
      return "DeclinedProductTerm8";
    case EDealershipTemplateFieldType.DeclinedProductMileage8:
      return "DeclinedProductMileage8";
    case EDealershipTemplateFieldType.DeclinedProductName9:
      return "DeclinedProductName9";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice9:
      return "DeclinedProductMonthlyPrice9";
    case EDealershipTemplateFieldType.DeclinedProductPrice9:
      return "DeclinedProductPrice9";
    case EDealershipTemplateFieldType.DeclinedProductTerm9:
      return "DeclinedProductTerm9";
    case EDealershipTemplateFieldType.DeclinedProductMileage9:
      return "DeclinedProductMileage9";
    case EDealershipTemplateFieldType.DeclinedProductName10:
      return "DeclinedProductName10";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice10:
      return "DeclinedProductMonthlyPrice10";
    case EDealershipTemplateFieldType.DeclinedProductPrice10:
      return "DeclinedProductPrice10";
    case EDealershipTemplateFieldType.DeclinedProductTerm10:
      return "DeclinedProductTerm10";
    case EDealershipTemplateFieldType.DeclinedProductMileage10:
      return "DeclinedProductMileage10";
    case EDealershipTemplateFieldType.DeclinedProductName11:
      return "DeclinedProductName11";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice11:
      return "DeclinedProductMonthlyPrice11";
    case EDealershipTemplateFieldType.DeclinedProductPrice11:
      return "DeclinedProductPrice11";
    case EDealershipTemplateFieldType.DeclinedProductTerm11:
      return "DeclinedProductTerm11";
    case EDealershipTemplateFieldType.DeclinedProductMileage11:
      return "DeclinedProductMileage11";
    case EDealershipTemplateFieldType.DeclinedProductName12:
      return "DeclinedProductName12";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice12:
      return "DeclinedProductMonthlyPrice12";
    case EDealershipTemplateFieldType.DeclinedProductPrice12:
      return "DeclinedProductPrice12";
    case EDealershipTemplateFieldType.DeclinedProductTerm12:
      return "DeclinedProductTerm12";
    case EDealershipTemplateFieldType.DeclinedProductMileage12:
      return "DeclinedProductMileage12";
    case EDealershipTemplateFieldType.DeclinedProductName13:
      return "DeclinedProductName13";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice13:
      return "DeclinedProductMonthlyPrice13";
    case EDealershipTemplateFieldType.DeclinedProductPrice13:
      return "DeclinedProductPrice13";
    case EDealershipTemplateFieldType.DeclinedProductTerm13:
      return "DeclinedProductTerm13";
    case EDealershipTemplateFieldType.DeclinedProductMileage13:
      return "DeclinedProductMileage13";
    case EDealershipTemplateFieldType.DeclinedProductName14:
      return "DeclinedProductName14";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice14:
      return "DeclinedProductMonthlyPrice14";
    case EDealershipTemplateFieldType.DeclinedProductPrice14:
      return "DeclinedProductPrice14";
    case EDealershipTemplateFieldType.DeclinedProductTerm14:
      return "DeclinedProductTerm14";
    case EDealershipTemplateFieldType.DeclinedProductMileage14:
      return "DeclinedProductMileage14";
    case EDealershipTemplateFieldType.DeclinedProductName15:
      return "DeclinedProductName15";
    case EDealershipTemplateFieldType.DeclinedProductMonthlyPrice15:
      return "DeclinedProductMonthlyPrice15";
    case EDealershipTemplateFieldType.DeclinedProductPrice15:
      return "DeclinedProductPrice15";
    case EDealershipTemplateFieldType.DeclinedProductTerm15:
      return "DeclinedProductTerm15";
    case EDealershipTemplateFieldType.DeclinedProductMileage15:
      return "DeclinedProductMileage15";
    case EDealershipTemplateFieldType.SelectedTerm:
      return "SelectedTerm";
    case EDealershipTemplateFieldType.Customer_Shared_PreviousFullAddress:
      return "Customer_Shared_PreviousFullAddress";
    case EDealershipTemplateFieldType.Customer_Shared_IncomeInterval:
      return "Customer_Shared_IncomeInterval";
    case EDealershipTemplateFieldType.VehicleFuelType:
      return "VehicleFuelType";
    case EDealershipTemplateFieldType.TotalMileageAtLeaseEnd:
      return "TotalMileageAtLeaseEnd";
    case EDealershipTemplateFieldType.PaymentScheduleType:
      return "PaymentScheduleType";
    case EDealershipTemplateFieldType.GstTax:
      return "GstTax";
    case EDealershipTemplateFieldType.PstTax:
      return "PstTax";
    case EDealershipTemplateFieldType.LuxuryTax:
      return "LuxuryTax";
    case EDealershipTemplateFieldType.NumberOfPayments:
      return "NumberOfPayments";
    case EDealershipTemplateFieldType.VehicleInteriorColor:
      return "VehicleInteriorColor";
    case EDealershipTemplateFieldType.AcquisitionFee:
      return "AcquisitionFee";
    case EDealershipTemplateFieldType.TradeColor:
      return "TradeColor";
    case EDealershipTemplateFieldType.SecondTradeColor:
      return "SecondTradeColor";
    case EDealershipTemplateFieldType.SecondTradePayOff:
      return "SecondTradePayOff";
    case EDealershipTemplateFieldType.LeaseMileage:
      return "LeaseMileage";
    case EDealershipTemplateFieldType.Customer_Shared_DriverLicenseState:
      return "Customer_Shared_DriverLicenseState";
    case EDealershipTemplateFieldType.ServiceContractTermInYears:
      return "ServiceContractTermInYears";
    case EDealershipTemplateFieldType.ServiceContractMileage:
      return "ServiceContractMileage";
    case EDealershipTemplateFieldType.CapitalizedFeeName1:
      return "CapitalizedFeeName1";
    case EDealershipTemplateFieldType.CapitalizedFeeValue1:
      return "CapitalizedFeeValue1";
    case EDealershipTemplateFieldType.CapitalizedFeeName2:
      return "CapitalizedFeeName2";
    case EDealershipTemplateFieldType.CapitalizedFeeValue2:
      return "CapitalizedFeeValue2";
    case EDealershipTemplateFieldType.CapitalizedFeeName3:
      return "CapitalizedFeeName3";
    case EDealershipTemplateFieldType.CapitalizedFeeValue3:
      return "CapitalizedFeeValue3";
    case EDealershipTemplateFieldType.CapitalizedFeeName4:
      return "CapitalizedFeeName4";
    case EDealershipTemplateFieldType.CapitalizedFeeValue4:
      return "CapitalizedFeeValue4";
    case EDealershipTemplateFieldType.CapitalizedFeeName5:
      return "CapitalizedFeeName5";
    case EDealershipTemplateFieldType.CapitalizedFeeValue5:
      return "CapitalizedFeeValue5";
    case EDealershipTemplateFieldType.CapitalizedFeeName6:
      return "CapitalizedFeeName6";
    case EDealershipTemplateFieldType.CapitalizedFeeValue6:
      return "CapitalizedFeeValue6";
    case EDealershipTemplateFieldType.CapitalizedFeeName7:
      return "CapitalizedFeeName7";
    case EDealershipTemplateFieldType.CapitalizedFeeValue7:
      return "CapitalizedFeeValue7";
    case EDealershipTemplateFieldType.CapitalizedFeeName8:
      return "CapitalizedFeeName8";
    case EDealershipTemplateFieldType.CapitalizedFeeValue8:
      return "CapitalizedFeeValue8";
    case EDealershipTemplateFieldType.CapitalizedFeeName9:
      return "CapitalizedFeeName9";
    case EDealershipTemplateFieldType.CapitalizedFeeValue9:
      return "CapitalizedFeeValue9";
    case EDealershipTemplateFieldType.CapitalizedFeeName10:
      return "CapitalizedFeeName10";
    case EDealershipTemplateFieldType.CapitalizedFeeValue10:
      return "CapitalizedFeeValue10";
    case EDealershipTemplateFieldType.CapitalizedFeeName11:
      return "CapitalizedFeeName11";
    case EDealershipTemplateFieldType.CapitalizedFeeValue11:
      return "CapitalizedFeeValue11";
    case EDealershipTemplateFieldType.CapitalizedFeeName12:
      return "CapitalizedFeeName12";
    case EDealershipTemplateFieldType.CapitalizedFeeValue12:
      return "CapitalizedFeeValue12";
    case EDealershipTemplateFieldType.CapitalizedFeeName13:
      return "CapitalizedFeeName13";
    case EDealershipTemplateFieldType.CapitalizedFeeValue13:
      return "CapitalizedFeeValue13";
    case EDealershipTemplateFieldType.CapitalizedFeeName14:
      return "CapitalizedFeeName14";
    case EDealershipTemplateFieldType.CapitalizedFeeValue14:
      return "CapitalizedFeeValue14";
    case EDealershipTemplateFieldType.CapitalizedFeeName15:
      return "CapitalizedFeeName15";
    case EDealershipTemplateFieldType.CapitalizedFeeValue15:
      return "CapitalizedFeeValue15";
    case EDealershipTemplateFieldType.UpfrontFeeName1:
      return "UpfrontFeeName1";
    case EDealershipTemplateFieldType.UpfrontFeeValue1:
      return "UpfrontFeeValue1";
    case EDealershipTemplateFieldType.UpfrontFeeName2:
      return "UpfrontFeeName2";
    case EDealershipTemplateFieldType.UpfrontFeeValue2:
      return "UpfrontFeeValue2";
    case EDealershipTemplateFieldType.UpfrontFeeName3:
      return "UpfrontFeeName3";
    case EDealershipTemplateFieldType.UpfrontFeeValue3:
      return "UpfrontFeeValue3";
    case EDealershipTemplateFieldType.UpfrontFeeName4:
      return "UpfrontFeeName4";
    case EDealershipTemplateFieldType.UpfrontFeeValue4:
      return "UpfrontFeeValue4";
    case EDealershipTemplateFieldType.UpfrontFeeName5:
      return "UpfrontFeeName5";
    case EDealershipTemplateFieldType.UpfrontFeeValue5:
      return "UpfrontFeeValue5";
    case EDealershipTemplateFieldType.UpfrontFeeName6:
      return "UpfrontFeeName6";
    case EDealershipTemplateFieldType.UpfrontFeeValue6:
      return "UpfrontFeeValue6";
    case EDealershipTemplateFieldType.UpfrontFeeName7:
      return "UpfrontFeeName7";
    case EDealershipTemplateFieldType.UpfrontFeeValue7:
      return "UpfrontFeeValue7";
    case EDealershipTemplateFieldType.UpfrontFeeName8:
      return "UpfrontFeeName8";
    case EDealershipTemplateFieldType.UpfrontFeeValue8:
      return "UpfrontFeeValue8";
    case EDealershipTemplateFieldType.UpfrontFeeName9:
      return "UpfrontFeeName9";
    case EDealershipTemplateFieldType.UpfrontFeeValue9:
      return "UpfrontFeeValue9";
    case EDealershipTemplateFieldType.UpfrontFeeName10:
      return "UpfrontFeeName10";
    case EDealershipTemplateFieldType.UpfrontFeeValue10:
      return "UpfrontFeeValue10";
    case EDealershipTemplateFieldType.UpfrontFeeName11:
      return "UpfrontFeeName11";
    case EDealershipTemplateFieldType.UpfrontFeeValue11:
      return "UpfrontFeeValue11";
    case EDealershipTemplateFieldType.UpfrontFeeName12:
      return "UpfrontFeeName12";
    case EDealershipTemplateFieldType.UpfrontFeeValue12:
      return "UpfrontFeeValue12";
    case EDealershipTemplateFieldType.UpfrontFeeName13:
      return "UpfrontFeeName13";
    case EDealershipTemplateFieldType.UpfrontFeeValue13:
      return "UpfrontFeeValue13";
    case EDealershipTemplateFieldType.UpfrontFeeName14:
      return "UpfrontFeeName14";
    case EDealershipTemplateFieldType.UpfrontFeeValue14:
      return "UpfrontFeeValue14";
    case EDealershipTemplateFieldType.UpfrontFeeName15:
      return "UpfrontFeeName15";
    case EDealershipTemplateFieldType.UpfrontFeeValue15:
      return "UpfrontFeeValue15";
    case EDealershipTemplateFieldType.CapitalizedFees:
      return "CapitalizedFees";
    case EDealershipTemplateFieldType.StateTax:
      return "StateTax";
    case EDealershipTemplateFieldType.CountyTax:
      return "CountyTax";
    case EDealershipTemplateFieldType.CityTax:
      return "CityTax";
    case EDealershipTemplateFieldType.UpfrontFees:
      return "UpfrontFees";
    case EDealershipTemplateFieldType.MonthlyTaxPayment:
      return "MonthlyTaxPayment";
    case EDealershipTemplateFieldType.TitleRegistrationFee:
      return "TitleRegistrationFee";
    case EDealershipTemplateFieldType.TotalPriceWithOptions:
      return "TotalPriceWithOptions";
    case EDealershipTemplateFieldType.TotalSellingPrice:
      return "TotalSellingPrice";
    case EDealershipTemplateFieldType.CustomField:
      return "CustomField";
    case EDealershipTemplateFieldType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
