import { getDocument } from 'pdfjs-dist';
import { range } from 'lodash-es';
import type { IFusePdfPage } from './types';

const SAFARI_CANVAS_PIXEL_LIMIT = 16777216;

const getCanvasSize = (pageWidth: number, pageHeight: number): { width: number; height: number } => {
	const requiredPixels = pageWidth * pageHeight;

	if (requiredPixels <= SAFARI_CANVAS_PIXEL_LIMIT) {
		return { width: pageWidth, height: pageHeight };
	}

	const scale = Math.sqrt(SAFARI_CANVAS_PIXEL_LIMIT) / Math.sqrt(requiredPixels);

	return {
		width: Math.floor(pageWidth * scale),
		height: Math.floor(pageHeight * scale),
	};
};

export const base64ToArrayBuffer = (base64: string): Float64Array => {
	const binaryString = window.atob(base64);
	const len = binaryString.length;
	const bytes = new Uint8Array(len);

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return bytes.buffer as Float64Array;
};

/**
 * Receives a URL to a PDF file and returns an array of base64 encoded images, along with their original dimensions
 * @param base64
 */
export const convertPdfToImages = async (base64: string): Promise<IFusePdfPage[]> => {
	const arrayBuffer = base64ToArrayBuffer(base64);
	const pdf = await getDocument({ data: arrayBuffer }).promise;

	return Promise.all(
		range(1, pdf.numPages + 1).map(async (pageNumber): Promise<IFusePdfPage> => {
			const canvas = document.createElement('canvas');

			const scale = 5;
			const page = await pdf.getPage(pageNumber);
			const viewport = page.getViewport({ scale });
			const context = canvas.getContext('2d');

			const { width: canvasWidth, height: canvasHeight } = getCanvasSize(viewport.width, viewport.height);

			canvas.height = Math.abs(canvasHeight);
			canvas.width = Math.abs(canvasWidth);

			await page.render({ canvasContext: context, viewport }).promise;
			const imageBase64 = canvas.toDataURL();

			canvas.width = 0;
			canvas.height = 0;
			canvas.remove();

			return { base64: imageBase64, width: viewport.width / scale, height: viewport.height / scale };
		})
	);
};

export const getImageDimensions = (base64: string): Promise<{ width: number; height: number }> =>
	new Promise((resolve, reject) => {
		const image = new Image();

		image.onload = (): void => {
			resolve({ width: image.naturalWidth, height: image.naturalHeight });
		};

		image.onerror = reject;
		image.src = `data:image/png;base64,${base64}`;
	});
