// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.3
//   protoc               v5.28.3
// source: passwordlessAuth/v1/enums/linkType.proto

/* eslint-disable */

export const protobufPackage = "passwordlessAuth.v1.enums.linkType";

export enum EPasswordlessAuthLinkType {
  Unspecified = 0,
  CreditApplication = 1,
  SignDisclosure = 2,
  SignDocuments = 3,
  UNRECOGNIZED = -1,
}

export function ePasswordlessAuthLinkTypeFromJSON(object: any): EPasswordlessAuthLinkType {
  switch (object) {
    case 0:
    case "Unspecified":
      return EPasswordlessAuthLinkType.Unspecified;
    case 1:
    case "CreditApplication":
      return EPasswordlessAuthLinkType.CreditApplication;
    case 2:
    case "SignDisclosure":
      return EPasswordlessAuthLinkType.SignDisclosure;
    case 3:
    case "SignDocuments":
      return EPasswordlessAuthLinkType.SignDocuments;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPasswordlessAuthLinkType.UNRECOGNIZED;
  }
}

export function ePasswordlessAuthLinkTypeToJSON(object: EPasswordlessAuthLinkType): string {
  switch (object) {
    case EPasswordlessAuthLinkType.Unspecified:
      return "Unspecified";
    case EPasswordlessAuthLinkType.CreditApplication:
      return "CreditApplication";
    case EPasswordlessAuthLinkType.SignDisclosure:
      return "SignDisclosure";
    case EPasswordlessAuthLinkType.SignDocuments:
      return "SignDocuments";
    case EPasswordlessAuthLinkType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
