// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.3
//   protoc               v5.28.3
// source: common/v1/enums/Platforms.proto

/* eslint-disable */

export const protobufPackage = "common.v1.enums.platforms";

export enum EPlatforms {
  Unspecified = 0,
  Transact = 1,
  DR = 2,
  Backoffice = 3,
  UNRECOGNIZED = -1,
}

export function ePlatformsFromJSON(object: any): EPlatforms {
  switch (object) {
    case 0:
    case "Unspecified":
      return EPlatforms.Unspecified;
    case 1:
    case "Transact":
      return EPlatforms.Transact;
    case 2:
    case "DR":
      return EPlatforms.DR;
    case 3:
    case "Backoffice":
      return EPlatforms.Backoffice;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPlatforms.UNRECOGNIZED;
  }
}

export function ePlatformsToJSON(object: EPlatforms): string {
  switch (object) {
    case EPlatforms.Unspecified:
      return "Unspecified";
    case EPlatforms.Transact:
      return "Transact";
    case EPlatforms.DR:
      return "DR";
    case EPlatforms.Backoffice:
      return "Backoffice";
    case EPlatforms.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
