// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.3
//   protoc               v5.28.3
// source: deal/v1/enums/DealStatus.proto

/* eslint-disable */

export const protobufPackage = "deal.v1.enums.dealStatus";

export enum EDealStatus {
  Unspecified = 0,
  Desking = 1,
  Review = 2,
  Application = 3,
  Decision = 4,
  Menu = 5,
  Documents = 6,
  Done = 7,
  Submitted = 8,
  UNRECOGNIZED = -1,
}

export function eDealStatusFromJSON(object: any): EDealStatus {
  switch (object) {
    case 0:
    case "Unspecified":
      return EDealStatus.Unspecified;
    case 1:
    case "Desking":
      return EDealStatus.Desking;
    case 2:
    case "Review":
      return EDealStatus.Review;
    case 3:
    case "Application":
      return EDealStatus.Application;
    case 4:
    case "Decision":
      return EDealStatus.Decision;
    case 5:
    case "Menu":
      return EDealStatus.Menu;
    case 6:
    case "Documents":
      return EDealStatus.Documents;
    case 7:
    case "Done":
      return EDealStatus.Done;
    case 8:
    case "Submitted":
      return EDealStatus.Submitted;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EDealStatus.UNRECOGNIZED;
  }
}

export function eDealStatusToJSON(object: EDealStatus): string {
  switch (object) {
    case EDealStatus.Unspecified:
      return "Unspecified";
    case EDealStatus.Desking:
      return "Desking";
    case EDealStatus.Review:
      return "Review";
    case EDealStatus.Application:
      return "Application";
    case EDealStatus.Decision:
      return "Decision";
    case EDealStatus.Menu:
      return "Menu";
    case EDealStatus.Documents:
      return "Documents";
    case EDealStatus.Done:
      return "Done";
    case EDealStatus.Submitted:
      return "Submitted";
    case EDealStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
